import { useCallback, useState } from 'react';

export const useDisclosure = () => {
	const [isOpen, setIsOpen] = useState(false);

	const open = useCallback(() => {
		setIsOpen(true);
	}, []);

	const close = useCallback(() => {
		setIsOpen(false);
	}, []);

	const toggle = useCallback(() => {
		setIsOpen((v) => !v);
	}, []);

	return {
		isOpen,
		open,
		close,
		toggle,
	};
};

export const useDisclosureWithParams = <Params>() => {
	const [params, setParams] = useState<Params | null>(null);

	const open = useCallback((params: Params) => {
		setParams(params);
	}, []);

	const close = useCallback(() => {
		setParams(null);
	}, []);

	return {
		isOpen: params !== null,
		params,
		open,
		close,
	};
};
