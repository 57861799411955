import React from 'react';

import { Center, createStyles, Divider, Stack, Title } from '@tf/ui';

import type { SanctionListItem } from '../types';

import { Item } from './Item';

const useStyles = createStyles(({ colors }) => ({
	border: {
		borderColor: colors.gray[2],
	},
}));

interface Props {
	sanctionList?: SanctionListItem[];
}

export const SanctionList: React.FC<Props> = ({ sanctionList }) => {
	const { classes } = useStyles();

	if (!sanctionList) return null;

	if (sanctionList.length > 0) {
		return (
			<Stack gap={0}>
				{sanctionList.map((item, index) => (
					<React.Fragment key={`${item.name}-${index}`}>
						<Item item={item} />
						{index !== sanctionList.length - 1 && <Divider className={classes.border} my="sm" />}
					</React.Fragment>
				))}
			</Stack>
		);
	}

	return (
		<Center h={300}>
			<Stack align="center" gap="sm">
				<Title order={4} c="dimmed">
					No sanction list items found
				</Title>
			</Stack>
		</Center>
	);
};
