import React from 'react';

import { createStyles } from '@tf/ui';

const useStyles = createStyles(() => ({
	label: {
		margin: '.25rem 0',
		fontWeight: 500,
		fontSize: '14px',
	},
}));

interface LabelFieldProps {
	label: string;
}

export const LabelField: React.FC<LabelFieldProps> = ({ label }) => {
	const { classes } = useStyles();
	return <span className={classes.label}>{label}</span>;
};
