import { useMemo } from 'react';

import { useSession, useSignIn } from '@clerk/clerk-react';

export const useSignInWithTicket = () => {
	const { signIn, setActive } = useSignIn();
	const { session } = useSession();

	return useMemo(() => {
		if (!signIn) {
			return null;
		}

		return async (ticket: string) => {
			await session?.end();

			const signInResource = await signIn.create({ strategy: 'ticket', ticket });
			await setActive({ session: signInResource.createdSessionId });
		};
	}, [signIn, setActive]);
};
