import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import type { Country } from 'react-phone-number-input';
import { isSupportedCountry } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import { Input } from '@tf/ui';

import 'react-phone-number-input/style.css';

import { useConfigContext } from '../../../hooks';
import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

export function PhoneInput({ name, label, isReadOnly, isRequired, tooltip }: DefaultInputProps) {
	const form = useFormContext();

	useEffect(() => {
		const value = form.getValues(name);
		form.setValue(name, value);
	}, []);

	const defs = useConfigContext((s) => s);
	const countries = defs.getEnumOptions('common__enum__country') || [];
	const europeCountryCodes = countries
		.filter((c) => c.group === 'Europe' && isSupportedCountry(c.value))
		.map((c) => c.value as Country);

	return (
		<Controller
			name={name}
			control={form.control}
			render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
				return (
					<Input.Wrapper label={label} labelProps={{ required: isRequired }}>
						<PhoneInputWithCountry
							name={name}
							countryOptionsOrder={['🌐', ...europeCountryCodes, '|', '...']}
							error={Boolean(error)}
							readOnly={isReadOnly}
							onChange={(value: string | null) => {
								if (value === null) onChange('');
								else onChange(value);
							}}
							withCountryCallingCode
							international
							value={value}
							inputComponent={Input}
							ref={ref}
							numberInputProps={{
								rightSection: <TooltipIcon tooltip={tooltip} error={error} />,
							}}
						/>
					</Input.Wrapper>
				);
			}}
		/>
	);
}
