import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Anchor, Box, type BoxProps, Breadcrumbs, createStyles, Title } from '@tf/ui';

import { usePageTitle } from './usePageTitle';

const useStyles = createStyles(({ colors, breakpoints }) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '0 auto',
		maxWidth: breakpoints.xl,
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: colors?.light?.[2] || colors.gray[2],
		minHeight: 61,
		marginBottom: '.75rem',
	},
	navigation: {
		display: 'flex',
		alignItems: 'start',
		flexDirection: 'column',
		flex: 1,
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 500,
		color: colors?.light?.[7] || colors.gray[7],
		cursor: 'default',
		userSelect: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export interface PageHeaderBreadcrumb {
	title: string;
	href: string;
}

export interface TFPageHeaderProps extends BoxProps {
	title: string;
	toolbar?: React.ReactNode;
	breadcrumbs?: PageHeaderBreadcrumb[];
	tenant: string;
	badge?: React.ReactNode;
}

export const TFPageHeader: React.FC<TFPageHeaderProps> = ({
	title,
	toolbar,
	breadcrumbs,
	tenant,
	badge,
	...props
}) => {
	const { classes } = useStyles();

	usePageTitle(title, tenant);
	const navigate = useNavigate();

	return (
		<Box className={classes.root} {...props}>
			<Box className={classes.navigation} m="1.25rem 0">
				{breadcrumbs && (
					<Breadcrumbs separator="/" mb=".5rem">
						{breadcrumbs.map((item) => (
							<Anchor
								href={item.href}
								key={item.href}
								onClick={(event) => {
									event.preventDefault();
									navigate(item.href);
								}}
							>
								{item.title}
							</Anchor>
						))}
					</Breadcrumbs>
				)}
				<Title className={classes.title}>
					{title} {badge}
				</Title>
			</Box>
			<Box>{toolbar}</Box>
		</Box>
	);
};
