import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box } from '@tf/ui';
import type { FieldDefinition } from '@tf/utils';

import { useConfigContext, useFormStore, useSegmentContext } from '../../hooks';
import { FIELD_MODIFIERS } from '../../utils';

import { ArrayField } from './inputs/ArrayField';
import Field from './Field';

interface Props {
	def: BackendTypes.FieldDefinition;
	path?: string;
}

type GenericFieldsProps = {
	isMultiple: boolean;
	nextDef: FieldDefinition;
	isHidden: boolean;
};

const GenericFields = ({ isMultiple, nextDef, isHidden }: GenericFieldsProps) => {
	const { fieldFilters } = useConfigContext((s) => s);
	if (isHidden) return null;

	const def = {
		...nextDef,
		isMultiple,
	};

	const preset = def.visual.preset || '';

	if (fieldFilters && !fieldFilters.includes(preset)) {
		return null;
	}

	// these two job structs have "multiple" modifier, but should be rendered with custom component
	if (preset === FIELD_MODIFIERS.LIVENESS_CHECK || def.ref === 'CRACalculationJobInfo') {
		return (
			<Box>
				<Field def={def} />
			</Box>
		);
	}

	return (
		<Box>
			{isMultiple && nextDef.type !== 'enum' ? <ArrayField def={nextDef} /> : <Field def={def} />}
		</Box>
	);
};

const GenericField: React.FC<Props> = ({ def, path }) => {
	const form = useFormStore((s) => s);

	// * Consume segment context
	const { identity } = useSegmentContext();

	// * Compose field name
	const name = path ? `${path}.${def.name}` : def.name;

	// * Get access mode
	const accessMode = form.getAccessMode(identity.segmentKind);

	// * Get field rules
	const validationRules = form.getFieldValidationRules(identity.segmentKind, name);
	const visualRules = form.getFieldVisualRules(identity.segmentKind, name);
	const calculatedFields = form.getCalculatedFields(identity.segmentKind);
	const isFieldCalculated = Boolean(calculatedFields.find(({ path }) => path === name));
	const isHidden = visualRules.some((r) => r.rule === 'hide');

	// * Rewrite field definition
	const nextDef: FieldDefinition = {
		name,
		type: def.type,
		kind: def.kind,
		visual: def.visual,
		ref: def.ref,
		isReadOnly: accessMode === 'VIEW' || isFieldCalculated,
		isOptional: validationRules.every((rule) => rule.rule !== 'required'),
	};

	return <GenericFields isMultiple={def.isMultiple as boolean} nextDef={nextDef} isHidden={isHidden} />;
};

export default GenericField;
