import { useContext } from 'react';

import { useStore } from 'zustand';

import { ConfigContext } from '../contexts';
import type { ConfigStore } from '../stores';

export function useConfigContext<T>(
	selector: (state: ConfigStore) => T,
	equalityFn?: (left: T, right: T) => boolean
): T {
	const store = useContext(ConfigContext);
	if (!store) {
		throw new Error('Missing ConfigContext.Provider in the tree');
	}
	return useStore(store, selector, equalityFn);
}
