import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

export const jobGetQuery = async <JobType = BackendTypes.Job>({
	jobId,
}: {
	jobId: BackendTypes.JobId;
}): Promise<JobType | null> => {
	if (!jobId) {
		return null;
	}

	const res = await BackendClient.getInstance().getJob(jobId);
	return res.json();
};
