import { createStore } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import type { BackendTypes } from '@tf/api';
import type { FormSegment, ValidateSegmentError, ValidationRule, VisualRule } from '@tf/utils';

import type { FormRules } from '../types';

export interface SubmitHandlerOptions {
	data: Record<string, any>;
	segmentValidationStatuses: Record<string, 'OK' | 'VALIDATION_ERRORS'>;
}

type State = {
	segments: FormSegment[];
	rules: FormRules;
	hiddenFields: string[];
	errors: ValidateSegmentError[];
};

type Actions = {
	getAccessMode: (kind: BackendTypes.SegmentKind) => BackendTypes.AccessModeKind;
	getFieldValidationRules: (segmentKind: BackendTypes.SegmentKind, path: string) => ValidationRule[];
	getCalculatedFields: (segmentKind: BackendTypes.SegmentKind) => { path: string; value: string }[];
	getFieldVisualRules: (kind: BackendTypes.SegmentKind, path: string) => VisualRule[];
	getFieldErrors: (kind: BackendTypes.SegmentKind, path: string) => ValidateSegmentError[];
};

export type FormStore = State & Actions;

type CreateFormStoreOptions = Pick<State, 'segments' | 'rules'>;

export const createFormStore = ({ segments, rules }: CreateFormStoreOptions) => {
	return createStore<FormStore>()(
		subscribeWithSelector(
			devtools(
				(set, get) => ({
					segments,
					rules,
					errors: [],
					hiddenFields: ['files', 'reason', 'is_no_document', 'AdhocSearchJobInfo'],
					getAccessMode: (segmentKind) => {
						const segment = segments.find((s) => s.segmentKind === segmentKind);
						return segment?.accessMode ?? 'MODIFY';
					},
					getFieldValidationRules: (segmentKind, path) => {
						const rules = get().rules.validation[segmentKind];
						if (!rules) return [];
						// Path can be something like 'personalData.firstName' or 'personalData.0.firstName'
						const fullPath = path.replace(/[0-9]\./g, '');

						return rules.filter((r) => r.path === path || r.path === fullPath);
					},
					getCalculatedFields: (segmentKind) => {
						const fields = get().rules.calculatedFields[segmentKind];
						if (!fields) return [];
						return fields;
					},
					getFieldErrors: (segmentKind, path) => {
						const errors = get().errors;
						if (!errors) return [];
						// Path can be something like 'personalData.firstName' or 'personalData.0.firstName'
						const fullPath = `${segmentKind}.${path}`;
						return errors.filter((r) => {
							return [fullPath, path].includes(r.path.join('.'));
						});
					},
					getFieldVisualRules: (segmentKind, path) => {
						const rules = get().rules.visual[segmentKind];

						if (!rules) return [];

						const fullPath = path.replaceAll(/[0-9]\./g, '');
						return rules.filter((r) => r.path === path || r.path === fullPath);
					},
				}),
				{
					name: `Form`,
					enabled: true,
					serialize: { options: true },
					anonymousActionType: 'unknown',
				}
			)
		)
	);
};
