import React from 'react';

import { Box, type BoxProps } from '@tf/ui';

export type TFPageContentProps = React.PropsWithChildren & BoxProps;

export const TFPageContent: React.FC<TFPageContentProps> = ({ children, ...props }) => {
	return (
		<Box
			sx={({ breakpoints }) => ({
				width: '100%',
				maxWidth: breakpoints.xl,
				margin: '0 auto',
			})}
			{...props}
		>
			{children}
		</Box>
	);
};
