import { useContext } from 'react';

import { SegmentContext } from '../contexts';

export function useSegmentContext() {
	const context = useContext(SegmentContext);
	if (!context) {
		throw new Error('Missing SegmentContext.Provider in the tree');
	}

	return context;
}
