import React from 'react';

import { createStyles, Flex, Stack, TFText } from '@tf/ui';
import { fmt } from '@tf/utils';

const useStyles = createStyles(({ colors }) => ({
	wrapper: {
		cursor: 'default',
		borderRadius: '4px',
		backgroundColor: colors.gray[1],
	},
	username: {
		fontWeight: 600,
		color: colors.gray[5],
	},
	date: {
		minWidth: '110px',
		color: colors.gray[5],
	},
}));

interface Props {
	date: string;
	comment: string;
	username?: string;
}

export const JobResultComment: React.FC<Props> = ({ date, comment, username }) => {
	const { classes } = useStyles();

	return (
		<Flex gap={12} p="0.75rem" className={classes.wrapper}>
			<Stack gap="md">
				{Boolean(username) && (
					<TFText size="sm" lineClamp={1} className={classes.username}>
						{username}
					</TFText>
				)}
				<TFText size="sm" lineClamp={1} className={classes.date}>
					{fmt.toDate(date, { preset: 'full_date' })}
				</TFText>
			</Stack>
			<TFText size="sm">{comment}</TFText>
		</Flex>
	);
};
