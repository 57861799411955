import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

export const jobCreateMutation = async <Job extends BackendTypes.Job = BackendTypes.Job>({
	jobPayloadData,
	jobKind,
	identity,
}: {
	jobKind: Job['jobKind'];
	jobPayloadData: Job['payload'];
	identity: BackendTypes.SegmentIdentity;
}): Promise<Job> => {
	const res = await BackendClient.getInstance().createJob({
		jobKind,
		jobPayloadData,
		graphId: identity.graphId,
		segmentKind: identity.segmentKind,
	});

	return res.json();
};
