export enum FIELD_MODIFIERS {
	TEXTAREA = 'textarea',
	PERCENTAGE = 'percentage',
	DATE_PERIOD = 'date_period',
	DATE = 'date',
	DATE_MONTH_YEAR = 'date_month_year',
	ADDRESS = 'address',
	PHONE = 'phone',
	BIRTH_PLACE = 'birth_place',
	MONEY = 'money',
	MONEY_SELECT = 'money_select',
	MONEY_LABEL = 'money_label',
	DOCUMENTS_CHECK = 'documents_check',
	BACKGROUND_CHECK = 'background_check',
	LIVENESS_CHECK = 'liveness_check',
	LINK = 'link',
}

export const CURRENCY_SYMBOLS: Record<string, string> = {
	USD: '$',
	EUR: '€',
	GBP: '£',
	CHF: '₣',
};

export const idenfyErrorMessages: Record<string, string> = {
	BAD_VALUE: 'The provided value is invalid. Please check it and try again.',
	MISSING_VALUE: 'A required value is missing. Please provide all necessary information.',
	UNAUTHORIZED: 'Access denied. Please ensure you have permission to perform this action.',
	ENCODING_ERROR: 'There was an issue processing the data. Please try again.',
	JSON_ERROR: 'The request format is incorrect. Please check your input and try again.',
	TOKEN_NOT_VALID: 'Your session has expired. Please log in again.',
	PARTNER_CONTRACT_ERROR: 'Your account does not have permission to perform this action.',
	METHOD_NOT_ALLOWED: 'The request method is not allowed. Please check the request.',
	PERMISSIONS_ERROR: 'You do not have the required permissions for this request.',
	TOO_MANY_REQUESTS: 'Too many requests. Please wait and try again.',
	VALIDATION_ERROR: 'The request contains invalid data. Please review and try again.',
	BAD_IMAGE_FORMAT: 'The uploaded image format is not supported. Please use a different format.',
};
