import React, { useEffect } from 'react';

import { observer, useObservable } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';
import type { SearchHit } from '@tf/shared';
import { getResultHit, JobSearchResultCard } from '@tf/shared';
import {
	Box,
	Button,
	Center,
	createStyles,
	Flex,
	Icon,
	Stack,
	TFDataTable,
	TFText,
	Title,
	UnstyledButton,
} from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { JobResultComments } from '../JobResultComments';

const useStyles = createStyles(({ colors, radius }) => ({
	infoBlockWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	infoBlockContent: {
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		cursor: 'default',
		color: colors.dark[7],
	},
	fullName: {
		fontSize: 13,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		color: colors.gray[9],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
	backButton: {
		color: colors.brand[6],
		'&:hover': {
			color: colors.brand[7],
		},
	},
}));

interface ComponentState {
	cardId: string | null;
	checkedMatches: (string | number)[];
	comments: BackendTypes.MonitoringComment[] | null;
}

interface Props {
	hits: SearchHit[] | null;
	checkAsMatch: ({
		ids,
		comments,
	}: {
		ids: (string | number)[];
		comments: BackendTypes.AdhocComment[];
	}) => void;
}

export const JobResultTable: React.FC<Props> = observer(({ hits, checkAsMatch }) => {
	const { classes } = useStyles();

	const state = useObservable<ComponentState>({
		cardId: null,
		checkedMatches: [],
		comments: null,
	});

	if (!hits) return null;

	const profiles = hits.map((item) => {
		return {
			id: item.doc.id,
			entityType: item.doc.entity_type,
			fullName: item.doc.name,
			countries: item.doc.fields
				? item.doc.fields.filter((i) => i.tag === 'country_names').map((i) => i.value)
				: [],
			dateOfBirth: item.doc.fields
				? item.doc.fields
						.filter((i) => i.tag === 'date_of_birth' && i.source === 'complyadvantage')
						.map((i) => i.value)
						.join(', ')
				: '',
		};
	});

	const toggleMatch = (id: string | number) => {
		const checkedMatches = [...state.checkedMatches.get()];

		if (checkedMatches.length && checkedMatches[0] === -1) {
			checkedMatches.pop();
		}

		if (checkedMatches.includes(id)) {
			checkedMatches.splice(checkedMatches.indexOf(id), 1);
		} else {
			checkedMatches.push(id);
		}

		state.checkedMatches.set(checkedMatches);
		state.comments.set(null);
	};

	useEffect(() => {
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		checkAsMatch({ ids: state.checkedMatches.get(), comments: state.comments.get() });
	}, [state.checkedMatches.get(), state.comments.get()]);

	// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
	const checkedCardId = hits[state.cardId.get()]?.doc?.id;
	const isCardChecked = state.checkedMatches.get().includes(checkedCardId);

	return (
		<>
			{hits.length ? (
				// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
				state.cardId.get() > -1 && hits[state.cardId.get()] ? (
					<Stack pt="0.5rem">
						<UnstyledButton onClick={() => state.cardId.set(null)}>
							<Flex className={classes.backButton}>
								<Icon.IconChevronLeft size={18} />
								Back
							</Flex>
						</UnstyledButton>
						<JobSearchResultCard
							// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
							hit={getResultHit(hits[state.cardId.get()])}
							withInnerCard={false}
						/>
						<Button
							variant="light"
							onClick={() => toggleMatch(checkedCardId)}
							color={isCardChecked ? 'gray' : 'indigo'}
						>
							<TFText inherit>{isCardChecked ? 'Remove match' : "It's match"}</TFText>
						</Button>
					</Stack>
				) : (
					<Stack>
						<TFDataTable
							name="Matches list"
							data={profiles}
							paperProps={{ sx: { borderRadius: '8px' } }}
							defs={[
								{
									header: 'Full name',
									accessorKey: 'fullName',
									enableSorting: true,
									size: 200,
									Cell: ({ row }) => {
										const { fullName } = row.original;
										return (
											<UnstyledButton
												className={classes.fullName}
												onClick={() => {
													state.cardId.set(row.id);
												}}
											>
												<TFText inherit lineClamp={1}>
													{fullName || 'N/A'}
												</TFText>
											</UnstyledButton>
										);
									},
								},
								{
									header: 'Entity type',
									accessorKey: 'entityType',
									size: 100,
									Cell: ({ row }) => {
										const { entityType } = row.original;
										return (
											<TFText inherit lineClamp={1}>
												{S.prettify(entityType) || 'N/A'}
											</TFText>
										);
									},
								},
								{
									header: 'Countries',
									accessorKey: 'countries',
									size: 200,
									enableSorting: true,
									Cell: ({ row }) => {
										const { countries } = row.original;
										return (
											<div>
												{countries && countries.length
													? countries.map((item: string, index: number) => (
															<Box key={`${item}-${index}`}>
																<TFText inherit>{item || 'N/A'}</TFText>
															</Box>
													  ))
													: 'N/A'}
											</div>
										);
									},
								},
								{
									header: 'Date of birth',
									accessorKey: 'dateOfBirth',
									size: 100,
									Cell: ({ row }) => {
										const { dateOfBirth } = row.original;
										if (dateOfBirth)
											return (
												<TFText inherit lineClamp={1}>
													{fmt.toDate(dateOfBirth, { preset: 'full_date' })}
												</TFText>
											);
										else
											return (
												<TFText inherit c="dimmed" lineClamp={1}>
													N/A
												</TFText>
											);
									},
								},
								{
									header: 'Action',
									accessorKey: 'id',
									enableSorting: false,
									enableColumnDragging: false,
									enableResizing: false,
									size: 135,
									maxSize: 135,
									Cell: ({ row }) => {
										const { id } = row.original;
										return (
											<Button
												color={
													state.checkedMatches.get().includes(id) ? 'gray' : 'rgba(37, 99, 235, 0.08)'
												}
												fullWidth
												variant="light"
												onClick={() => toggleMatch(id)}
											>
												<TFText c={state.checkedMatches.get().includes(id) ? 'gray' : 'brand'} inherit>
													{state.checkedMatches.get().includes(id) ? 'Unmatch' : 'Match'}
												</TFText>
											</Button>
										);
									},
								},
							]}
						/>
						{state.checkedMatches.get().length && state.checkedMatches.get()[0] === -1 ? (
							<JobResultComments
								// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
								initialComments={state.comments.get()}
								// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
								setComments={state.comments.set}
							/>
						) : null}
						<Button
							color={
								state.checkedMatches.get() && state.checkedMatches.get()[0] === -1 ? 'gray' : 'brand'
							}
							variant="light"
							onClick={() => state.checkedMatches.set([-1])}
						>
							<TFText inherit>None of the above</TFText>
						</Button>
					</Stack>
				)
			) : (
				<Center h={150}>
					<Stack align="center" gap="sm">
						<Title order={4} c="dimmed">
							No records found
						</Title>
					</Stack>
				</Center>
			)}
		</>
	);
});
