import React, { useEffect, useRef, useState } from 'react';

import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMutation } from '@tanstack/react-query';
import { Controller, useController, useFormContext } from 'react-hook-form';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	Button,
	createStyles,
	FileButton,
	Flex,
	Icon,
	Input,
	Loader,
	Stack,
	Textarea,
	TFNotifier,
	TFSection,
	Tooltip,
} from '@tf/ui';

import { useConfigContext, useSegmentContext } from '../../../../hooks';
import { TooltipIcon } from '../../TooltipIcon';
import type { DefaultInputProps } from '../../types';

import { DocumentsCheckComponent } from './extensions/DocumentsCheck';
import DocumentItem from './DocumentItem';

export interface Props extends DefaultInputProps {
	extensions?: string[];
	fieldPath: string;
}

enum NotificationKey {
	UploadSuccess = 'upload-success',
	UploadError = 'upload-error',
	DeleteSuccess = 'delete-success',
}

const useStyles = createStyles(({ colors, radius }) => ({
	uploadButton: {
		fontWeight: 500,
		fontSize: 13,
		border: `1px solid transparent`,
		backgroundColor: colors.gray[1],
		'&:disabled': {
			color: colors.gray[5],
			backgroundColor: colors.gray[1],
		},
	},
	uploadButton__error: {
		border: `1px solid ${colors.red[3]}`,
		backgroundColor: colors.red[1],
		color: colors.dark[5],
		'&:not([data-disabled]):hover': {
			backgroundColor: colors.red[2],
		},
		borderColor: colors.red[1],
	},
	switchButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			color: colors.brand[7],
		},
	},
	textInputDisabled: {
		textArea: {
			cursor: 'default',
			backgroundColor: colors.gray[1],
			borderColor: colors.gray[1],
			borderBottomWidth: 1,
			borderBottomColor: colors.gray[2],
			'&:focus': {
				borderColor: colors.gray[1],
				borderBottomWidth: 1,
				borderBottomColor: colors.gray[8],
			},
		},
	},
	emptyWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	emptyContent: {
		flex: 1,
		color: colors.dark[6],
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontSize: 13,
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
	},
}));

interface FilesState {
	files: BackendTypes.FieldDocumentFile[];
	is_no_document: boolean;
	reason: string;
	document_check_job: BackendTypes.DocumentCheckJobInfo;
}

export default function FileInput({
	name,
	label,
	extensions = [],
	isReadOnly,
	isRequired,
	tooltip,
}: Props) {
	const formContext = useFormContext();
	const { classes } = useStyles();
	const { _ } = useLingui();
	const { fileUploadFn } = useConfigContext((s) => s.apiConfig);
	const { identity } = useSegmentContext();

	const [document, setDocument] = useState<FilesState>({
		files: formContext.getValues(`${name}.files`) ?? [],
		is_no_document: formContext.getValues(`${name}.is_no_document`) ?? false,
		reason: formContext.getValues(`${name}.reason`) ?? '',
		document_check_job: formContext.getValues(`${name}.document_check_job`) ?? {},
	});

	useEffect(() => {
		formContext.setValue(`${name}.files`, document.files, { shouldDirty: true });
		formContext.setValue(`${name}.is_no_document`, document.is_no_document, { shouldDirty: true });
		if (extensions.includes('DOCUMENT_CHECK')) {
			formContext.setValue(`${name}.document_check_job`, document.document_check_job, {
				shouldDirty: true,
			});
		}
	}, [document]);

	const uploadFileMutation = useMutation({
		mutationFn: fileUploadFn,
	});

	const handleFileUpload = async (file: File | null) => {
		if (!file) return;
		try {
			const fileData = await uploadFileMutation.mutateAsync({
				file,
				identity,
			});

			setDocument((prevState) => ({
				...prevState,
				files: [...prevState.files, { file: fileData, isOutdated: false }],
			}));
		} catch (e) {
			TFNotifier.error(
				_(msg({ id: 'fb.fileInput.notifications.uploadFailed', message: 'Document upload failed' })),
				{
					id: NotificationKey.UploadError,
				}
			);
		}
	};

	const resetFileRef = useRef<() => void>(null);
	const handleFileDelete = async (target: BackendTypes.FieldFile) => {
		setDocument((prevState) => ({
			...prevState,
			files: prevState.files.filter((f) => f.file.storageKey !== target.storageKey),
		}));
		resetFileRef.current?.();
	};

	const handleCreatedJob = (jobInfo: BackendTypes.DocumentCheckJobInfo) => {
		setDocument((prevState) => ({
			...prevState,
			document_check_job: {
				id: jobInfo.id,
				payload: jobInfo.payload,
			},
		}));
	};

	const toggleAvailability = () => {
		const is_no_document = !document.is_no_document;

		setDocument((prevState) => ({ ...prevState, is_no_document }));
		if (!is_no_document) {
			formContext.setValue(`${name}.reason`, undefined, { shouldDirty: true });
		}
	};

	const {
		fieldState: { error: reasonError },
	} = useController({
		control: formContext.control,
		name: `${name}.reason`,
	});

	const isDocumentsCheckButtonVisible = () => {
		return extensions.includes('DOCUMENT_CHECK');
	};

	return (
		<TFSection
			title={
				<Flex align="center">
					<Input.Label m={0} required={isRequired}>
						{label || _(msg({ id: 'fb.fileInput.title', message: 'Document' }))}
					</Input.Label>
					{tooltip ? <TooltipIcon style={{ margin: '0 2px 0 4px' }} tooltip={tooltip} /> : undefined}
				</Flex>
			}
			icon={<Icon.IconFile size={18} />}
			actions={
				!isReadOnly &&
				isRequired && (
					<Tooltip
						label={
							document.is_no_document
								? _(msg({ id: 'fb.fileInput.markAsAvailable', message: 'Mark a document as available' }))
								: _(
										msg({
											id: 'fb.fileInput.markAsUnavailable',
											message: 'Mark a document as unavailable',
										})
								  )
						}
						withArrow
						position="left"
					>
						<Button
							id={`${name}-mark-button`}
							size="xs"
							variant="subtle"
							disabled={isReadOnly}
							className={classes.switchButton}
							onClick={toggleAvailability}
						>
							{document.is_no_document
								? _(msg({ id: 'fb.fileInput.markAsAvailable', message: 'Mark a document as available' }))
								: _(
										msg({
											id: 'fb.fileInput.markAsUnavailable',
											message: 'Mark a document as unavailable',
										})
								  )}
						</Button>
					</Tooltip>
				)
			}
		>
			<Controller
				control={formContext.control}
				name={`${name}.files`}
				render={({ field: { value } }) => (
					<Box hidden={document.is_no_document}>
						<Stack gap={6}>
							{document.files.length > 0 && (
								<Stack gap={6} p={0} align="center" hidden={document.is_no_document}>
									{document.files.map((f, fileIndex: number) => (
										<DocumentItem
											key={f.file.filename + fileIndex}
											file={f.file}
											onDelete={(v) => handleFileDelete(v)}
											isReadOnly={isReadOnly}
										/>
									))}
								</Stack>
							)}
							<Box hidden={isReadOnly}>
								<FileButton
									accept="image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									resetRef={resetFileRef}
									onChange={handleFileUpload}
									name={`${name}.files`}
								>
									{(props) => {
										return (
											<Stack gap={9}>
												<Button
													{...props}
													size="sm"
													variant="light"
													fullWidth
													leftSection={
														!uploadFileMutation.isPending && <Icon.IconUpload size={16} stroke={1.8} />
													}
													disabled={isReadOnly || uploadFileMutation.isPending}
													className={`${classes.uploadButton} ${
														isRequired && !value?.length && reasonError
															? classes.uploadButton__error
															: ''
													}`}
												>
													{uploadFileMutation.isPending ? (
														<Loader size={14} />
													) : (
														_(
															msg({
																id: 'fb.fileInput.uploadDocumentButton',
																message: 'Upload document',
															})
														)
													)}
												</Button>
											</Stack>
										);
									}}
								</FileButton>
							</Box>
							<Box hidden={!isDocumentsCheckButtonVisible()}>
								<DocumentsCheckComponent
									identity={identity}
									onCreatedJob={handleCreatedJob}
									isReadOnly={!!isReadOnly}
									files={document.files.map((f) => f.file)}
									checkedDocument={document.document_check_job}
								/>
							</Box>
							<Box hidden={!(isReadOnly && !document.files.length)}>
								<Box className={classes.emptyWrapper}>
									<Box className={classes.emptyContent}>
										<Trans id="fb.fileInput.noDocuments">No documents uploaded</Trans>
									</Box>
								</Box>
							</Box>
						</Stack>
					</Box>
				)}
			/>

			<Controller
				control={formContext.control}
				name={`${name}.reason`}
				render={({ fieldState: { error } }) => (
					<Box hidden={!document.is_no_document}>
						<Input.Wrapper
							label={_(
								msg({
									id: 'fb.fileInput.missingFileReasonLabel',
									message: 'Reason',
								})
							)}
							labelProps={{ required: isRequired }}
						>
							<Textarea
								id={`${name}-description`}
								readOnly={isReadOnly}
								error={Boolean(error)}
								className={isReadOnly ? classes.textInputDisabled : ''}
								rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
								placeholder={_(
									msg({
										id: 'fb.fileInput.missingFileReasonPlaceholder',
										message: 'Reason for missing file...',
									})
								)}
								{...formContext.register(`${name}.reason`)}
							/>
						</Input.Wrapper>
					</Box>
				)}
			/>
		</TFSection>
	);
}
