import React from 'react';

import { Box, Button, createStyles, Modal, useDisclosure } from '@tf/ui';
import type { CorporateBasicAccountData, IndividualBasicAccountData } from '@tf/utils';

import { SearchForm } from './SearchForm';

const useStyles = createStyles(({ colors }) => ({
	switchButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			color: colors.brand[7],
		},
	},
}));

interface Props {
	isReadOnly: boolean;
	isIndividual: boolean;
	initialValue?: { entity?: CorporateBasicAccountData; person?: IndividualBasicAccountData };
	onSubmit: (data: any) => void;
}

export const EditDataButton: React.FC<Props> = ({
	isReadOnly,
	isIndividual,
	initialValue,
	onSubmit,
}) => {
	const { classes } = useStyles();
	const { toggle, isOpen } = useDisclosure();

	return (
		<>
			<Button size="xs" variant="subtle" className={classes.switchButton} onClick={toggle}>
				{isReadOnly ? 'Show' : 'Edit'} data for searching
			</Button>
			<Modal opened={isOpen} title="Search parameters" onClose={toggle}>
				<Box p="1rem">
					<SearchForm
						isIndividual={isIndividual}
						isReadOnly={isReadOnly}
						initialValue={initialValue}
						onSubmit={onSubmit}
						onClose={toggle}
					/>
				</Box>
			</Modal>
		</>
	);
};
