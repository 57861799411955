import { getEdgePosition } from './get-edge-position';
import { getNodeIntersection } from './get-node-intersection';
import type { StructureNode } from './types';

/**
 * Returns the parameters to create an edge
 * @param source
 * @param target
 */
export const getEdgeParams = (source: StructureNode, target: StructureNode) => {
	const sourceIntersectionPoint = getNodeIntersection(source, target);
	const targetIntersectionPoint = getNodeIntersection(target, source);

	const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
	const targetPos = getEdgePosition(target, targetIntersectionPoint);

	return {
		sx: sourceIntersectionPoint.x,
		sy: sourceIntersectionPoint.y,
		tx: targetIntersectionPoint.x,
		ty: targetIntersectionPoint.y,
		sourcePos,
		targetPos,
	};
};
