type Item = { value: string; label: string; group?: string };
type GroupedData = { group: string; items: { value: string; label: string }[] };

export function transformData(data: Item[]) {
	const hasGrouping = data.some((item) => item.group);

	if (!hasGrouping) {
		return data;
	}

	return data.reduce<GroupedData[]>((acc, { group, value, label }) => {
		if (group) {
			const existingGroup = acc.find((g) => g.group === group);
			const newItem = { value, label };
			if (existingGroup) {
				existingGroup.items.push(newItem);
			} else {
				acc.push({ group, items: [newItem] });
			}
		}
		return acc;
	}, []);
}
