import React, { useEffect } from 'react';

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { Flex, Input, TextInput, TFChip, TFText } from '@tf/ui';

import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

export default function CheckboxInput({
	name,
	label,
	isReadOnly,
	className,
	isRequired,
	tooltip,
}: DefaultInputProps) {
	const { control, getValues, setValue } = useFormContext();
	const { _ } = useLingui();

	useEffect(() => {
		setValue(name, getValues(name) ?? false);
	}, []);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value }, fieldState: { error } }) => {
				return (
					<Input.Wrapper
						labelProps={{ style: { display: 'flex' } }}
						className={className}
						label={
							<Flex align={'center'}>
								<TFText>{label}</TFText>
								<TooltipIcon style={{ margin: '0 2px 0 4px' }} tooltip={tooltip} />
							</Flex>
						}
						error={Boolean(error)}
						required={isRequired}
					>
						{!isReadOnly ? (
							<Flex>
								<TFChip
									id={`${name}-yes`}
									label={_(msg({ id: 'fb.checkboxInput.toggle.yes', message: 'Yes' }))}
									type="radio"
									isActive={value === true}
									onClick={() => onChange(true)}
								/>
								<TFChip
									id={`${name}-no`}
									style={{ marginLeft: '8px' }}
									label={_(msg({ id: 'fb.checkboxInput.toggle.no', message: 'No' }))}
									type="radio"
									isActive={value === false}
									onClick={() => onChange(false)}
								/>
							</Flex>
						) : (
							<TextInput
								value={
									value
										? _(msg({ id: 'fb.checkboxInput.toggle.yes', message: 'Yes' }))
										: _(msg({ id: 'fb.checkboxInput.toggle.no', message: 'No' }))
								}
								readOnly
							/>
						)}
					</Input.Wrapper>
				);
			}}
		/>
	);
}
