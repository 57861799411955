import React, { useEffect, useRef } from 'react';

import deepEqual from 'deep-equal';
import type { MutableRefObject } from 'react';
import { FormProvider as RHFormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import type { StoreApi } from 'zustand';

import {
	type FormSegment,
	type FormValues,
	O,
	validateSegment,
	ValidateSegmentErrorType,
} from '@tf/utils';

import { FormContext } from '../contexts';
import { useConfigContext } from '../hooks';
import { createFormStore, type FormStore, type SubmitHandlerOptions } from '../stores';
import type { FormRules } from '../types';

export interface FormControl {
	getValues: () => FormValues;
	getErrors: () => Record<string, any>;
	validate: () => boolean;
}

interface FormStoreProviderProps extends React.PropsWithChildren {
	segments: FormSegment[];
	initialValues?: FormValues;
	shouldValidate?: boolean;
	validateInitialValues?: boolean;
	formControlRef?: MutableRefObject<FormControl | undefined>;
	submit: (opts: SubmitHandlerOptions) => Promise<void>;
}

const FormStoreProvider: React.FC<FormStoreProviderProps> = ({
	children,
	segments,
	initialValues,
	shouldValidate,
	validateInitialValues = false,
	formControlRef,
	submit,
}) => {
	const { getSegmentRules, getStructDefinitionDeep, getDataWithCompositeValues } = useConfigContext(
		(s) => s
	);

	const form = useFormContext();
	const formValues = useWatch();

	const storeRef = useRef<StoreApi<FormStore>>();
	if (!storeRef.current) {
		storeRef.current = createFormStore({
			segments,
			rules: {
				validation: {},
				visual: {},
				calculatedFields: {},
			},
		});
	}

	useEffect(() => {
		if (!formControlRef) {
			return;
		}

		formControlRef.current = {
			getValues: () => getDataWithCompositeValues(form.getValues()),
			getErrors: () => form.formState.errors,
			validate: () => {
				const { isValid } = validateForm(form.getValues());
				return isValid;
			},
		};
	}, [form]);

	const segmentKinds = segments.map((s) => s.segmentKind);
	useEffect(() => {
		const rules: FormRules = getMergedRules();
		storeRef.current?.setState((s) => {
			return {
				...s,
				segments,
				rules: {
					validation: rules.validation,
					visual: rules.visual,
					calculatedFields: rules.calculatedFields ?? [],
				},
			};
		});
	}, [formValues, segments]);

	useEffect(() => {
		if (validateInitialValues && initialValues) {
			validateForm(initialValues, { skipFocusOnError: true });
		}
	}, [validateInitialValues]);

	const prevCalculatedFields = useRef<any>({});
	useEffect(() => {
		const calculatedFields = storeRef.current?.getState().rules.calculatedFields;
		if (deepEqual(prevCalculatedFields.current, calculatedFields)) {
			return;
		}

		segments.forEach(({ segmentKind }) => {
			const calculatedFields = storeRef.current?.getState().rules.calculatedFields[segmentKind];
			if (!calculatedFields?.length) return;

			calculatedFields.forEach((f) => {
				form.setValue(`${segmentKind}.${f.path}`, f.value);
			});
		});

		prevCalculatedFields.current = calculatedFields;
	}, [storeRef.current?.getState().rules.calculatedFields]);

	const validateForm = (data: Record<string, any>, options: { skipFocusOnError?: boolean } = {}) => {
		form.clearErrors();

		// * Trim all values and exclude redundant keys
		const values = segmentKinds.reduce((acc, key) => {
			return {
				...acc,
				[key]: O.beautify(data[key]),
			};
		}, {} as Record<string, Record<string, any>>);

		// * Validate all segments
		const errors = new Map<any, { message: string; type: ValidateSegmentErrorType }>();
		const segmentValidationStatuses: Record<string, 'OK' | 'VALIDATION_ERRORS'> = {};

		const rules = storeRef.current?.getState().rules;
		for (const [segmentKey, segmentValues] of Object.entries(values)) {
			const segmentKind = segmentKey;
			const segmentRules = rules?.validation[segmentKind] || [];

			const result = validateSegment({
				values: segmentValues,
				rules: segmentRules,
				visualRules: rules?.visual[segmentKind] || [],
				def: getStructDefinitionDeep(segmentKind),
			});

			segmentValidationStatuses[segmentKind] = result.isValid ? 'OK' : 'VALIDATION_ERRORS';

			if (!result.isValid) {
				for (const error of result.errors) {
					const path = [segmentKind, ...error.path].join('.');
					// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
					errors.set(path, { message: error.message, type: error.type });
				}
			}
		}

		storeRef.current?.setState((s) => {
			return { ...s, errors: Array.from(errors, ([path, value]) => ({ path: [path], ...value })) };
		});

		if (errors.size > 0) {
			if (!options.skipFocusOnError) {
				const elements = [...errors.keys()]
					.map((name) => document.getElementById(name))
					.filter((element): element is HTMLElement => Boolean(element));
				elements.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
				elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}

			errors.forEach(({ message, type }, path) => {
				if (!type || type === ValidateSegmentErrorType.FIELD) {
					form.setError(path, { message });
				}
			});
			return { isValid: false, segmentValidationStatuses };
		}

		return { isValid: true, segmentValidationStatuses };
	};

	const submitForm = form.handleSubmit(async (data: Record<string, any>) => {
		const { isValid, segmentValidationStatuses } = validateForm(data);

		if (!isValid && shouldValidate) {
			return;
		}

		const resultData = getDataWithCompositeValues(data);

		await submit({ data: resultData, segmentValidationStatuses });

		const rules: FormRules = getMergedRules();
		storeRef.current?.setState((s) => {
			return { ...s, rules };
		});

		validateForm(resultData);
	});

	const getMergedRules = () => {
		const rules: FormRules = {
			validation: {},
			visual: {},
			calculatedFields: {},
		};

		// TODO: скрипты процессинга могут зависить от данных других сегментов,
		//  поэтому нужно передавать все данные с бэка :thinking:
		segmentKinds.forEach((kind) => {
			const segmentRules = getSegmentRules({ data: formValues, segmentKind: kind });

			rules.visual[kind] = segmentRules.visual || [];
			rules.validation[kind] = segmentRules.validation || [];
			rules.calculatedFields[kind] = segmentRules.calculatedFields || [];
		});

		return rules;
	};

	return (
		<FormContext.Provider value={storeRef.current}>
			<form
				onSubmit={(e) => {
					form.clearErrors();
					return submitForm(e);
				}}
			>
				{children}
			</form>
		</FormContext.Provider>
	);
};

interface Props extends React.PropsWithChildren {
	segments: FormSegment[];
	initialValues?: FormValues;
	shouldValidate?: boolean;
	validateInitialValues?: boolean;
	formControlRef?: MutableRefObject<FormControl | undefined>;
	submit: (opts: SubmitHandlerOptions) => Promise<void>;
}

export const FormProvider: React.FC<Props> = ({
	segments,
	initialValues,
	shouldValidate,
	validateInitialValues,
	submit,
	children,
	formControlRef,
}) => {
	const formProps: any = {
		// This allows to clear errors that were set by setError before form submission
		// Needed to align validation behavior in Client Area and TF itself
		mode: 'onChange',
		shouldFocusError: shouldValidate,
		shouldUnregister: true,
	};

	if (initialValues) {
		formProps.defaultValues = initialValues;
	}

	const form = useForm(formProps);

	return (
		<RHFormProvider {...form}>
			<FormStoreProvider
				initialValues={initialValues}
				segments={segments}
				shouldValidate={shouldValidate}
				validateInitialValues={validateInitialValues}
				submit={submit}
				formControlRef={formControlRef}
			>
				{children}
			</FormStoreProvider>
		</RHFormProvider>
	);
};
