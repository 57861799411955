import React from 'react';

import { useLingui } from '@lingui/react';
import { DatesProvider as MantineDatesProvider } from '@mantine/dates';
import dayjs from 'dayjs';
// https://github.com/mantinedev/mantine/issues/4557
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

dayjs.locale('en');
dayjs.extend(customParseFormat);

export const DatesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { i18n } = useLingui();
	return <MantineDatesProvider settings={{ locale: i18n.locale }}>{children}</MantineDatesProvider>;
};
