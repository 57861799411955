import React, { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';

import type { BackendTypes } from '@tf/api';
import { Alert, Box, Button, Center, createStyles, Flex, TFText, Tooltip } from '@tf/ui';
import { fmt } from '@tf/utils';

import { jobGetQuery } from '../../../../queries';
import type { DefaultInputProps, IdenfyCheckResult, IdenfyJobResult } from '../../types';
import { JobStatus } from '../../types';
import type { DocumentCheckJob } from '../FileInput/extensions/DocumentsCheck/types';
import { getIdenfyCheckResultMessage, VerificationResultModal } from '../VerificationResultModal';

const useStyles = createStyles(({ colors }) => ({
	root: {
		backgroundColor: colors.gray[0],
		margin: '.6rem auto',
	},
	label: {
		margin: '.25rem 0',
		fontWeight: 500,
		fontSize: '14px',
	},
	alert: {
		padding: '0.3rem 0.5rem',
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.brand[1],
		'& .trustform-Alert-wrapper': {
			alignItems: 'center',
		},
		'& .trustform-Alert-icon': {
			alignItems: 'center',
			width: '1.6rem',
		},
	},
	modalAlert: {
		'& .trustform-Alert-title': {
			color: colors.dark[5],
		},
	},
	text: {
		fontSize: '.8rem',
	},
}));

// TODO: field must not be tied to specific segment
const SEGMENT_NAME_WITH_LIVENESS_CHECK = 'COMMON__BACKGROUND_CHECK';

export const LivenessCheck: React.FC<DefaultInputProps> = ({ name }) => {
	const { classes } = useStyles();

	const { register, getValues } = useFormContext();

	const jobsIds: BackendTypes.LivenessCheckJobsInfo =
		getValues()?.[SEGMENT_NAME_WITH_LIVENESS_CHECK]?.liveness_check_jobs;

	if (!jobsIds) return null;

	return (
		<>
			<span className={classes.label}>Liveness verification</span>
			<input type="hidden" {...register(`${name}`)} />
			{jobsIds.map(({ id }) => (
				<CheckResult key={id} jobId={id} />
			))}
		</>
	);
};

const map: Record<string, string> = {
	photo_face: 'Face photo',
	photo_front: 'Document front side',
	photo_back: 'Document back side',
};

interface CheckResultProps {
	jobId: string;
}

const CheckResult: React.FC<CheckResultProps> = ({ jobId }) => {
	const { classes } = useStyles();
	const [jobResultStatus, setJobResultStatus] = useState<IdenfyCheckResult | null>(null);
	const [jobStatus, setJobStatus] = useState<JobStatus | null>(null);
	const [isSearching, setIsSearching] = useState(false);
	const [urlSelectOptions, setUrlSelectOptions] = useState<{ label: string; value: string }[]>([]);

	const livenessCheckJob = useQuery({
		queryKey: ['livenessCheck', jobId],
		queryFn: () => {
			setIsSearching(true);
			return jobGetQuery({ jobId });
		},
		refetchInterval: 2500,
		enabled: jobStatus !== JobStatus.FAILED && jobStatus !== JobStatus.SUCCESS,
	});

	useEffect(() => {
		if (livenessCheckJob.data) {
			const jobResult = (livenessCheckJob.data.result as any).result as IdenfyJobResult;
			const status = jobResult.status?.overall;

			const fileUrls = (livenessCheckJob.data.result as any)?.fileUrls;
			setUrlSelectOptions(
				Object.entries<any>(fileUrls)
					.filter(([key]) => map[key])
					.map(([key, value]) => ({ label: map[key], value }))
			);
			if (status) {
				setJobResultStatus(status);
			}

			setJobStatus(livenessCheckJob.data.status as JobStatus);

			if (
				livenessCheckJob.data.status === JobStatus.FAILED ||
				livenessCheckJob.data.status === JobStatus.SUCCESS
			) {
				setIsSearching(false);
			}
		}
	}, [livenessCheckJob.data]);

	const [isModalOpen, setModalIsOpen] = useState(false);
	const toggleModal = () => setModalIsOpen(!isModalOpen);

	if (!jobResultStatus || isSearching || !livenessCheckJob.data) {
		return (
			<Box className={classes.root}>
				<Alert className={classes.alert}>
					<Center sx={{ flex: 1 }}>Verification in progress...</Center>
				</Alert>
			</Box>
		);
	}

	return (
		<>
			<VerificationResultModal
				job={livenessCheckJob.data as DocumentCheckJob}
				isOpen={isModalOpen}
				onModalClose={toggleModal}
				imageUrls={urlSelectOptions}
			/>
			<Box className={classes.root}>
				<Alert
					icon={
						<Tooltip
							label={getIdenfyCheckResultMessage(jobResultStatus).label}
							position="top-start"
							withArrow
							withinPortal
						>
							<Center>{getIdenfyCheckResultMessage(jobResultStatus).icon}</Center>
						</Tooltip>
					}
					sx={{
						color: `rgb(${getIdenfyCheckResultMessage(jobResultStatus).baseColor})`,
					}}
					className={classes.alert}
				>
					<Flex align={'center'} justify={'space-between'}>
						<TFText className={classes.text}>{`Verified (${fmt.toDate(
							livenessCheckJob.data.createdAt
						)})`}</TFText>
						<Button sx={{ height: '20px' }} size="sm" variant="subtle" onClick={toggleModal}>
							{!isModalOpen ? 'Show details' : 'Hide details'}
						</Button>
					</Flex>
				</Alert>
			</Box>
		</>
	);
};
