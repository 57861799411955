import React from 'react';

import { Box, Stack } from '@tf/ui';
import type { FormSegment } from '@tf/utils';

import { useConfigContext, useFormStore } from '../hooks';
import { SegmentProvider } from '../providers';
import type { FormBuilderStyles } from '../types';

import FormFooter from './FormFooter';
import { GenericField } from './GenericField';

interface Props {
	isLoading?: boolean;
	styles?: Partial<FormBuilderStyles>;
	segmentPreRenderer?: (segment: FormSegment) => React.ReactNode;
	submitButtonRenderer?: (isLoading: boolean) => React.ReactNode;
}

const FormBuilder: React.FC<Props> = ({
	isLoading,
	styles,
	segmentPreRenderer,
	submitButtonRenderer,
}) => {
	const { getStructDefinition } = useConfigContext((s) => s);
	const segments = useFormStore((s) => s.segments);

	return (
		<>
			<Stack gap={0} sx={styles?.content}>
				{segments.map(({ segmentKind, graphId, accessMode }) => {
					const segmentDef = getStructDefinition(segmentKind);
					const label = segmentDef.label || '';

					return (
						<SegmentProvider
							key={`${segmentKind}.${segmentKind}`}
							identity={{ graphId, segmentKind }}
							accessMode={accessMode}
						>
							<Box sx={styles?.segment}>
								{segmentPreRenderer?.({ segmentKind, graphId, accessMode, label })}
								<Stack gap={12}>
									{Object.values(segmentDef.fields).map((def) => {
										return <GenericField key={def.name} def={def} />;
									})}
								</Stack>
							</Box>
						</SegmentProvider>
					);
				})}
			</Stack>

			<FormFooter isLoading={isLoading} submitButtonRenderer={submitButtonRenderer} />
		</>
	);
};

export default FormBuilder;
