import React from 'react';

import type { PropsWithChildren } from 'react';

import { Center, createStyles, Stack, TFCard, TFText, Title } from '@tf/ui';

const useStyles = createStyles(() => ({
	root: {
		height: '100vh',
	},
	card: {
		zIndex: 10,
		minWidth: 480,
		maxWidth: 640,
		padding: '1.5rem 1.25rem',
	},
}));

interface Props extends PropsWithChildren {
	title?: string;
	text?: string;
}

export const TFErrorTemplate: React.FC<Props> = ({ title, text, children }) => {
	const { classes } = useStyles();
	return (
		<Center className={classes.root}>
			<TFCard>
				<Stack className={classes.card} gap=".25rem" align="center">
					<Title order={3} mb=".5rem">
						{title || 'Error'}
					</Title>
					<TFText size=".85rem" c="dimmed">
						{text || 'An error occurred during initialisation of the application.'}
					</TFText>
					{children}
				</Stack>
			</TFCard>
		</Center>
	);
};
