import { useMutation } from '@tanstack/react-query';

import { BackendClient } from '@tf/api';

export const useDownloadMonitoringSearchCertificateMutation = () => {
	return useMutation({
		mutationFn: async (searchId: string) => {
			const api = BackendClient.getInstance();
			const res = await api.monitoringSearchCertificatePdf(searchId);
			return res.arrayBuffer();
		},
	});
};
