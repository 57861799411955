import React from 'react';

import { useFormContext } from 'react-hook-form';

import type { BackendTypes } from '@tf/api';
import { Box, Button, Input, Loader, Stack, TFText } from '@tf/ui';
import { fmt } from '@tf/utils';

import { useSegmentJobs } from '../../../hooks/use-segment-jobs';
import type { DefaultInputProps } from '../types';

import DownloadLink from './FileInput/DownloadLink';

type JobRecord = {
	id: string;
};

type CRAJob = Omit<BackendTypes.Job, 'result'> & { result?: BackendTypes.CRACalculationResult };

const getCalculationResult = (document: BackendTypes.FieldFile) => {
	const dateWithExtension = document.filename.split('cra_result_')[1] ?? '';
	const [date, extension] = dateWithExtension.split('.');

	return {
		...document,
		filename: date
			? `CRA result of ${fmt.toDate(date, { preset: 'full_date' })}.${extension}`
			: document.filename,
	};
};

export const CRACalculationJobInfo: React.FC<DefaultInputProps> = ({ name, label, isReadOnly }) => {
	const { register, getValues, setValue } = useFormContext();

	const fieldValue = getValues(name);
	const jobRecords: JobRecord[] = fieldValue && Array.isArray(fieldValue) ? fieldValue : [];

	const segmentJobs = useSegmentJobs<CRAJob>('CLIENT_RISK_CALCULATION');

	const startNewCalculation = () => {
		segmentJobs.createJob(
			{},
			{
				onSuccess: (result) => {
					setValue(name, [...jobRecords, { id: result.id }]);
				},
			}
		);
	};

	return (
		<Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Input.Label>{label}</Input.Label>
				{!isReadOnly && (
					<Button
						size="sm"
						variant="light"
						loading={segmentJobs.isCreating}
						onClick={startNewCalculation}
					>
						Calculate risk rating
					</Button>
				)}
			</Box>

			{segmentJobs.data && segmentJobs.data?.length > 0 ? (
				<Stack mt="0.5rem" gap="0.25rem">
					{segmentJobs.data.map((job) => {
						return (
							<Box
								key={job.id}
								sx={{ padding: '0.5rem', border: '1px solid #CCC', borderRadius: '0.25rem' }}
							>
								<Stack gap="0.125rem">
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<div>Risk rating: {job.result?.riskLevel || 'N/A'}</div>
										<TFText fw={400} c="dimmed">
											{fmt.toDate(job.createdAt, { preset: 'full_date_with_time' })}
										</TFText>
									</Box>

									{job.status === 'PENDING' || job.status === 'IN_PROGRESS' ? (
										<Loader size={18} />
									) : null}

									{job.result?.document ? (
										<Box>
											Calculation: <DownloadLink file={getCalculationResult(job.result.document)} />
										</Box>
									) : null}

									{/* Default template has no filename */}
									{job.result?.template?.filename ? (
										<Box>
											CRA template: <DownloadLink file={job.result.template} />
										</Box>
									) : null}
								</Stack>
							</Box>
						);
					})}
				</Stack>
			) : segmentJobs.data?.length === 0 ? (
				<TFText c="dimmed">No previous risk rating available</TFText>
			) : segmentJobs.isError ? (
				<TFText c="dimmed">Failed to load calculations, please try again later</TFText>
			) : (
				<Box p="0.5rem">
					<Loader size={18} />
				</Box>
			)}

			{/* Keep this field, required for RHF integration */}
			<input type="hidden" {...register(`${name}`)} />
		</Box>
	);
};
