import React from 'react';

import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActionIcon, Box, createStyles, Group, Icon, Stack, TFText, Tooltip } from '@tf/ui';

import { TooltipIcon } from '../../TooltipIcon';

interface Props extends React.PropsWithChildren {
	title: string;
	index: number;
	onDelete: () => void;
	isReadOnly: boolean;
	tooltip?: string;
}

const useStyles = createStyles(({ colors, radius }) => ({
	root: {
		borderRadius: radius.sm,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'default',
		justifyContent: 'space-between',
		backgroundColor: '#eef4ff',
		padding: '.375rem .5rem',
		borderTopLeftRadius: radius.sm,
		borderTopRightRadius: radius.sm,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: colors.brand[1],
	},
	title: {
		fontWeight: 500,
		display: 'flex',
	},
	body: {
		padding: '.5rem .75rem 0',
		borderWidth: '0 1px 1px',
		borderBottomLeftRadius: radius.sm,
		borderBottomRightRadius: radius.sm,
		borderStyle: 'solid',
		borderColor: colors.gray[2],
	},
	counter: {
		marginLeft: 5,
		color: colors.brand[6],
	},
	deleteIcon: {
		marginLeft: '1rem',
	},
}));

const ArrayFieldItem: React.FC<Props> = ({ children, title, onDelete, index, isReadOnly, tooltip }) => {
	const { _ } = useLingui();
	const { classes } = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.header}>
				<Group gap={6} className={classes.title}>
					<TFText inherit className={classes.counter}>
						#{index + 1}
					</TFText>
					<TFText inherit>{title}</TFText>
					<TooltipIcon tooltip={tooltip}></TooltipIcon>
				</Group>

				{!isReadOnly && (
					<Tooltip
						label={_(msg({ id: 'fb.array.delete', message: `Delete ${title}` }))}
						withArrow
						position="left"
					>
						<ActionIcon size="xs" variant="subtle" onClick={onDelete} color="brand">
							<Icon.IconTrash size={14} stroke={1.8} />
						</ActionIcon>
					</Tooltip>
				)}
			</Box>
			<Stack gap={9} className={classes.body}>
				{children}
			</Stack>
		</Box>
	);
};

export default ArrayFieldItem;
