import type { BackendTypes } from '@tf/api';

export const getOwnershipInfo = (
	connectionKind: string,
	reviewData: BackendTypes.ReviewData,
	graphLinkId: number
) => {
	const possibleConnections = ['SHAREHOLDER', 'ACCOUNT_HOLDER', 'PARTNER'];

	if (!possibleConnections.includes(connectionKind)) {
		return undefined;
	}

	if (connectionKind === 'PARTNER') {
		return reviewData.listPartnershipsOwnershipInfo.find((s) => s.EntityConnectionId === graphLinkId);
	}

	return reviewData.listShareholdersOwnershipInfo.find((s) => s.EntityConnectionId === graphLinkId);
};
