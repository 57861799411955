import React from 'react';

import type { BackendTypes } from '@tf/api';

import { SegmentContext } from '../contexts';

interface Props extends React.PropsWithChildren {
	identity: BackendTypes.SegmentIdentity;
	accessMode?: BackendTypes.AccessModeKind;
}

export const SegmentProvider: React.FC<Props> = ({ children, identity, accessMode = 'MODIFY' }) => {
	return <SegmentContext.Provider value={{ identity, accessMode }}>{children}</SegmentContext.Provider>;
};
