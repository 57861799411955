import type { FieldDefinition } from '@tf/utils';

export interface FieldProps {
	def: FieldDefinition;
}

export interface DefaultInputProps {
	name: string;
	label?: string;
	description?: string;
	placeholder?: string;
	isReadOnly?: boolean;
	isRequired?: boolean;
	className?: string;
	tooltip?: string;
}

export enum JobStatus {
	PENDING = 'PENDING',
	IN_PROGRESS = 'IN_PROGRESS',
	FAILED = 'FAILED',
	SUCCESS = 'SUCCESS',
}

export enum IdenfyCheckResult {
	APPROVED = 'APPROVED',
	DENIED = 'DENIED',
	SUSPECTED = 'SUSPECTED',
	REVIEWING = 'REVIEWING',
	ACTIVE = 'ACTIVE',
	EXPIRED = 'EXPIRED',
	EXPIRED_DELETED = 'EXPIRED_DELETED',
	DELETED = 'DELETED',
	ARCHIVED = 'ARCHIVED',
}

export type IdenfyJobResult = {
	AML: null | string;
	LID: null | string;
	additionalData: Record<string, unknown>;
	additionalStepPdfUrls: Record<string, unknown>;
	additionalSteps: null | string;
	addressVerification: null | string;
	beneficiaryId: null | string;
	clientIp: null | string;
	clientIpCountry: null | string;
	clientLocation: null | string;
	companyId: null | string;
	data: IdenfyJobData;
	externalRef: null | string;
	final: boolean;
	finishTime: null | string;
	gdcMatch: null | string;
	manualAddress: null | string;
	manualAddressMatch: boolean;
	platform: string;
	questionnaireAnswers: null | unknown;
	registryCenterCheck: null | string;
	startTime: null | string;
	status: IdenfyJobStatus;
	utility_data: unknown[];
};

type IdenfyJobData = {
	additionalData: Record<string, unknown>;
	address: null | string;
	ageEstimate: null | number;
	authority: null | string;
	birthPlace: null | string;
	clientIpProxyRiskLevel: null | string;
	docBirthName: null | string;
	docDateOfIssue: null | string;
	docDob: null | string;
	docExpiry: null | string;
	docFirstName: null | string;
	docIssuingCountry: null | string;
	docLastName: null | string;
	docNationality: null | string;
	docNumber: null | string;
	docPersonalCode: null | string;
	docSex: null | string;
	docTemporaryAddress: null | string;
	docType: null | string;
	driverLicenseCategory: null | string;
	duplicateDocFaces: null | unknown;
	duplicateFaces: null | unknown;
	fullName: string;
	manuallyDataChanged: boolean;
	mothersMaidenName: null | string;
	orgAddress: null | string;
	orgAuthority: null | string;
	orgBirthName: null | string;
	orgBirthPlace: null | string;
	orgFirstName: null | string;
	orgLastName: null | string;
	orgMothersMaidenName: null | string;
	orgNationality: null | string;
	orgTemporaryAddress: null | string;
	selectedCountry: string;
};

export type IdenfyJobStatus = {
	additionalSteps: string;
	adverseMediaStatus: null | string;
	amlResultClass: null | string;
	autoDocument: string;
	autoFace: null | string;
	denyReasons: string[];
	fraudTags: string[];
	manualDocument: null | string;
	manualFace: null | string;
	mismatchTags: string[];
	overall: IdenfyCheckResult;
	pepsStatus: null | string;
	sanctionsStatus: null | string;
	suspicionReasons: string[];
};
