import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Input, Textarea } from '@tf/ui';

import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

export default function TextareaInput({
	name,
	label,
	description,
	placeholder,
	isReadOnly,
	isRequired,
	className,
	tooltip,
}: DefaultInputProps) {
	const { control, setValue, getValues } = useFormContext();

	useEffect(() => {
		const value = getValues(name);
		setValue(name, value || '');
	}, []);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
				<Input.Wrapper
					className={className}
					id={name}
					label={label}
					labelProps={{ required: isRequired }}
				>
					<Textarea
						id={name}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						ref={ref}
						placeholder={placeholder}
						description={description}
						readOnly={isReadOnly}
						error={Boolean(error)}
						autosize
						minRows={2}
						maxRows={5}
						rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
					/>
				</Input.Wrapper>
			)}
		/>
	);
}
