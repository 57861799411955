import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Anchor, Button, createStyles, TFNotifier } from '@tf/ui';
import { saveFile } from '@tf/utils';

import { useConfigContext } from '../../../../hooks';

interface DownloadLinkProps {
	view?: 'button' | 'link';
	name?: string;
	icon?: React.ReactElement;
	file: BackendTypes.FieldFile;
}

const useStyles = createStyles(() => ({
	anchor: { fontSize: '.85rem' },
}));

const DownloadLink: React.FC<DownloadLinkProps> = ({ file, view = 'link', name, icon }) => {
	const { fileDownloadFn } = useConfigContext((s) => s.apiConfig);
	const { classes } = useStyles();

	const downloadDocument = React.useCallback(async () => {
		try {
			const blob = await fileDownloadFn({
				storageKey: file.storageKey,
			});
			saveFile(blob, file.filename);
		} catch (e) {
			TFNotifier.error('Document download failed', {
				id: 'DownloadError',
			});
		}
	}, [file]);

	if (view === 'button') {
		return (
			<Button size="xs" variant="light" p="4px 8px" leftSection={icon} onClick={downloadDocument}>
				{name ?? file.filename}
			</Button>
		);
	}

	return (
		<>
			<Anchor className={classes.anchor} onClick={downloadDocument}>
				{name ?? file.filename}
			</Anchor>
		</>
	);
};

export default DownloadLink;
