import React from 'react';

import { Anchor, Box, createStyles, TFCard, TFText } from '@tf/ui';

const useStyles = createStyles(({ colors }) => ({
	item: {
		display: 'flex',
		padding: '.5rem 1rem',
		borderBottom: `1px solid ${colors.gray[2]}`,
		'&:nth-of-type(2n)': {
			backgroundColor: colors.gray[0],
		},
		'&:first-of-type': {
			borderRadius: '12px 12px 0 0',
		},
		'&:last-child': {
			borderBottom: 'none',
			borderRadius: '0 0 12px 12px',
		},
	},
}));

export const ItemDetails: React.FC<{ items: any[] }> = ({ items = [] }) => {
	const { classes } = useStyles();

	return (
		<TFCard sx={{ marginBottom: '1rem' }}>
			{items.map((item: any, index: number) => {
				return (
					<Box key={`${item.name}-${index}`} className={classes.item}>
						<Box sx={{ minWidth: 200 }}>
							<TFText size="sm" fw={500}>
								{item.name || 'N/A'}
							</TFText>
						</Box>
						<Box>
							{item.isURL ? (
								<Anchor href={item.value} size="sm" target="_blank" rel="noreferrer">
									{item.value}
								</Anchor>
							) : (
								<TFText size="sm">{item.value}</TFText>
							)}
						</Box>
					</Box>
				);
			})}
		</TFCard>
	);
};
