import { useEffect } from 'react';

const APPLICATION_NAME = 'TrustForm';

export function usePageTitle(pageTitle: string, tenant?: string) {
	const subtitle = tenant ? `${pageTitle} - ${tenant}` : pageTitle;

	useEffect(() => {
		document.title = `${subtitle} | ${APPLICATION_NAME}`;

		return () => {
			document.title = APPLICATION_NAME;
		};
	}, [subtitle]);
}
