import React, { useState } from 'react';

import { Box, createStyles, SegmentedControl, Stack, TFCard, Title } from '@tf/ui';

import { AdverseMedia } from './AdverseMedia';
import { KeyInfoTable } from './KeyInfoTable';
import { SanctionList } from './SanctionList';
import type { ResultHit } from './types';

const TABS = {
	KEY_INFO: {
		label: 'Key info',
		value: 'keyInfo',
	},
	ADVERSE_MEDIA: {
		label: 'Adverse media',
		value: 'adverseMedia',
	},
} as const;

const useStyles = createStyles(() => ({
	item: {
		margin: '1rem .25rem .25rem .25rem',
	},
}));

interface Props {
	hit: ResultHit;
	withInnerCard?: boolean;
}

export const JobSearchResultCard: React.FC<Props> = ({ hit, withInnerCard }) => {
	const { classes } = useStyles();
	const [selectedTab, setSelectedTab] = useState('keyInfo');

	return (
		<Stack>
			<SegmentedControl
				value={selectedTab}
				onChange={(value: string) => setSelectedTab(value)}
				fullWidth
				data={Object.values(TABS)}
			/>
			{selectedTab === TABS.KEY_INFO.value && (
				<InnerCard withCard={withInnerCard}>
					<KeyInfoTable keyInfo={hit.keyInfo} />
					<Title className={classes.item} order={4}>
						Sanction listing
					</Title>
					<SanctionList sanctionList={hit.sanctionList} />
				</InnerCard>
			)}
			{selectedTab === TABS.ADVERSE_MEDIA.value && (
				<InnerCard withCard={withInnerCard}>
					<AdverseMedia adverseMedia={hit.adverseMedia} />
				</InnerCard>
			)}
		</Stack>
	);
};

interface InnerCardProps {
	children: React.ReactNode;
	withCard?: boolean;
}

const InnerCard: React.FC<InnerCardProps> = ({ children, withCard = true }) => {
	if (withCard) {
		return (
			<TFCard>
				<Box sx={{ padding: '1rem' }}>{children}</Box>
			</TFCard>
		);
	}

	return <>{children}</>;
};
