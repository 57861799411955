import type { BackendTypes } from '@tf/api';

// TODO: split into files

const isSameSegment = (a: BackendTypes.SegmentIdentity, b: BackendTypes.SegmentIdentity) => {
	return a.graphId === b.graphId && a.segmentKind === b.segmentKind;
};

// Common fields between TF and CA forms needed for helpers in this file
type BasicFormDefinition = {
	name: string;
	order?: number;
	segments: { kind: string }[];
};

export type ExtendedFormDefinition<FormDefinition extends BasicFormDefinition> = Omit<
	FormDefinition,
	'segments' | 'order'
> & {
	order: number;
	segmentIdentities: BackendTypes.SegmentIdentity[];
};

const createExtendedFormDefinition = <FormDefinition extends BasicFormDefinition>(
	formDef: FormDefinition,
	segmentIdentities: BackendTypes.SegmentIdentity[]
): ExtendedFormDefinition<FormDefinition> => {
	const { segments, order, ...rest } = formDef;
	return {
		...rest,
		order: order ?? 10,
		segmentIdentities,
	};
};

export const getFormHash = (form: { segmentIdentities: BackendTypes.SegmentIdentity[] }) => {
	return form.segmentIdentities.map((s) => s.graphId).join('-');
};

export const selectForm = <FormDefinition extends BasicFormDefinition>({
	formDefs,
	segmentIdentities,
	formName,
	hash,
}: {
	formDefs: FormDefinition[];
	segmentIdentities: BackendTypes.SegmentIdentity[];
	formName: string;
	hash: string;
}): ExtendedFormDefinition<FormDefinition> => {
	const formDef = formDefs.find((f) => f.name === formName);
	if (!formDef) {
		throw new Error(`Form ${formName} not found`);
	}

	// Bind graphIds from hash to form segments
	const graphIds = hash.split('-').map((id) => parseInt(id, 10));
	const formSegmentIdentities = formDef.segments.map(({ kind }) => {
		const graphId = graphIds.shift();
		if (!graphId) {
			throw new Error(`Incorrect cursor for form ${formName}`);
		}
		return { segmentKind: kind, graphId };
	});

	const isFulfilled = formSegmentIdentities.every((formSegment) => {
		return segmentIdentities.find((segment) => isSameSegment(segment, formSegment));
	});
	if (!isFulfilled) {
		throw new Error(`Segments for the ${formName} form not matched`);
	}

	return createExtendedFormDefinition(formDef, formSegmentIdentities);
};

export const extractForms = <FormDefinition extends BackendTypes.FormDefinition>({
	formDefs,
	segmentIdentities,
}: {
	formDefs: FormDefinition[];
	segmentIdentities: BackendTypes.SegmentIdentity[];
}): ExtendedFormDefinition<FormDefinition>[] => {
	const nextForms: ExtendedFormDefinition<FormDefinition>[] = [];

	// Remove duplicates from input segments
	let availableSegmentIdentities = segmentIdentities.filter((s1, index, self) => {
		return index === self.findIndex((s2) => isSameSegment(s1, s2));
	});

	for (const formDef of formDefs) {
		const matchedSegmentIdentities: BackendTypes.SegmentIdentity[] = [];

		for (const formSegment of formDef.segments) {
			const matchedSegmentIdentity = availableSegmentIdentities.find(
				(segmentIdentity) => segmentIdentity.segmentKind === formSegment.kind
			);

			if (matchedSegmentIdentity) {
				matchedSegmentIdentities.push(matchedSegmentIdentity);
			}
		}

		// Add form if all segments are matched
		if (matchedSegmentIdentities.length === formDef.segments.length) {
			nextForms.push(createExtendedFormDefinition(formDef, matchedSegmentIdentities));

			// Remove matched segments from available
			const matchedKinds = matchedSegmentIdentities.map((s) => s.segmentKind);
			availableSegmentIdentities = availableSegmentIdentities.filter((s) => {
				return !matchedKinds.includes(s.segmentKind);
			});
		}
	}

	nextForms.sort((a, b) => a.order - b.order);

	return nextForms;
};
