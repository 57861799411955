import React, { useEffect, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { createStyles, Group, Icon, lighten, Stack, TFNotifier, TFText } from '@tf/ui';

import { jobCreateMutation } from '../../../../../../mutations';
import { jobGetQuery } from '../../../../../../queries';
import { idenfyErrorMessages } from '../../../../../../utils';
import { JobStatus } from '../../../../types';

import { DocumentCheckButton } from './DocumentCheckButton';
import { DocumentsCheckStatus } from './DocumentsCheckStatus';
import type { DocumentCheckJob } from './types';

const useStyles = createStyles(({ colors, radius }) => ({
	errorBlockWrapper: {
		cursor: 'default',
		backgroundColor: lighten(colors.red[1], 0.6),
		color: colors.red[6],
		display: 'flex',
		alignItems: 'center',
		borderRadius: radius.sm,
		padding: '.75rem .75rem',
	},
}));

export type DocumentCheckPayload = {
	backSideDocument: string | null;
	frontSideDocument: string | null;
	residencyCountry: string | null;
	documentType: BackendTypes.DocumentCheckKind | null;
	firstName: string;
	lastName: string;
	dateOfBirth: string | null;
};

const initialPayload: DocumentCheckPayload = {
	backSideDocument: null,
	frontSideDocument: null,
	residencyCountry: null,
	documentType: null,
	firstName: '',
	lastName: '',
	dateOfBirth: null,
};

interface Props {
	files: BackendTypes.FieldFile[];
	onCreatedJob: (jobInfo: BackendTypes.DocumentCheckJobInfo) => void;
	checkedDocument: BackendTypes.DocumentCheckJobInfo;
	identity: BackendTypes.SegmentIdentity;
	isReadOnly: boolean;
}

const SERVICE_PROVIDER = 'idenfy';

export const DocumentsCheckComponent: React.FC<Props> = ({
	identity,
	files,
	onCreatedJob,
	checkedDocument,
	isReadOnly,
}) => {
	const { classes } = useStyles();

	const [documentCheckJob, setDocumentCheckJob] = useState<DocumentCheckJob | null>(null);

	const [documentCheckPayload, setDocumentCheckPayload] = useState<DocumentCheckPayload>(initialPayload);

	const handleDocumentCheckPayloadChange = (key: keyof DocumentCheckPayload | null, value: string) => {
		if (!key) {
			setDocumentCheckPayload(initialPayload);
			return;
		}
		setDocumentCheckPayload((prev) => ({ ...prev, [key]: value }));
	};

	const handleCheckDocument = async () => {
		const frontSideFile = files.find(
			(file) => file.storageKey === documentCheckPayload.frontSideDocument
		);
		const backSideFile = files.find((file) => file.storageKey === documentCheckPayload.backSideDocument);
		if (
			!documentCheckPayload.firstName ||
			!documentCheckPayload.lastName ||
			!documentCheckPayload.dateOfBirth ||
			!documentCheckPayload.residencyCountry ||
			!documentCheckPayload.documentType ||
			!documentCheckPayload.documentType ||
			!frontSideFile
		) {
			return;
		}
		const resultJob = await createJobMutation
			.mutateAsync({
				jobPayloadData: {
					// dummy status to check idenfy jobs requests in dev mode
					// more info here https://documentation.idenfy.com/integration/DummySession#sending-request
					// extraParams: {
					// 	status: 'APPROVED',
					// 	manualFaceMatchResult: 'FACE_MATCH',
					// 	manualDocumentValidity: 'DOC_VALIDATED',
					// },
					firstName: documentCheckPayload.firstName,
					lastName: documentCheckPayload.lastName,
					dateOfBirth: documentCheckPayload.dateOfBirth,
					country: documentCheckPayload.residencyCountry,
					documentKind: documentCheckPayload.documentType,
					nodeId: identity.graphId,
					serviceKind: SERVICE_PROVIDER,
					frontSide: {
						filename: frontSideFile.filename,
						storageKey: frontSideFile.storageKey,
					},
					...(backSideFile
						? {
								backSide: {
									filename: backSideFile.filename,
									storageKey: backSideFile.storageKey,
								},
						  }
						: {}),
				},
				identity,
			})
			.catch(async (error) => {
				const defaultMessage = 'An unexpected error occurred. Please try again later.';
				if (error?.status === 424) {
					const res = await error.json();
					const errorMessage = idenfyErrorMessages[res?.code] ?? defaultMessage;
					TFNotifier.error(errorMessage);
				} else {
					TFNotifier.error(defaultMessage);
				}
			});

		if (!resultJob) return;

		setDocumentCheckJob(resultJob);
		onCreatedJob(resultJob);
	};

	const createJobMutation = useMutation<
		DocumentCheckJob,
		Error,
		{
			jobPayloadData: BackendTypes.DocumentCheckParams;
			identity: BackendTypes.SegmentIdentity;
		}
	>({
		mutationFn: ({ jobPayloadData, identity }) =>
			jobCreateMutation({
				jobKind: 'DOCUMENT_CHECK',
				jobPayloadData,
				identity,
			}),
	});

	const documentCheckQuery = useQuery({
		queryKey: ['documentCheck', checkedDocument?.id],
		queryFn: () => jobGetQuery({ jobId: checkedDocument?.id }),
		refetchInterval: 2500,
		enabled: Boolean(
			(documentCheckJob || checkedDocument?.id) &&
				documentCheckJob?.status !== JobStatus.FAILED &&
				documentCheckJob?.status !== JobStatus.SUCCESS
		),
	});

	useEffect(() => {
		if (documentCheckQuery.data) {
			setDocumentCheckJob(documentCheckQuery.data as DocumentCheckJob);
		}

		if (documentCheckQuery.error) {
			TFNotifier.error('Something went wrong. Please try again later');
		}
	}, [documentCheckQuery.data, documentCheckQuery.error]);

	const isCheckedFileNotExist = ({
		files,
		job,
	}: {
		files: BackendTypes.FieldFile[];
		job: DocumentCheckJob | null;
	}) => {
		if (!job?.payload?.frontSide?.storageKey) {
			return false;
		}

		return files.findIndex((file) => file.storageKey === job?.payload?.frontSide?.storageKey) < 0;
	};

	return (
		<>
			<Stack gap="md">
				{isCheckedFileNotExist({ files, job: documentCheckJob }) ? (
					<Group gap={6} className={classes.errorBlockWrapper}>
						<Icon.IconAlertCircle size={14} />
						<TFText size="sm" lineClamp={1}>
							Document check results are outdated.
							{!isReadOnly ? '' : 'Please check the new document'}
						</TFText>
					</Group>
				) : null}
				<DocumentsCheckStatus job={documentCheckJob} isReadOnly={isReadOnly} />
				{!isReadOnly ? (
					<DocumentCheckButton
						documentCheckPayload={documentCheckPayload}
						onDocumentCheckPayloadChange={handleDocumentCheckPayloadChange}
						checkedDocument={checkedDocument}
						onCheckDocument={handleCheckDocument}
						files={files}
					/>
				) : null}
			</Stack>
		</>
	);
};
