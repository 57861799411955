import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { BackendClient } from '@tf/api';
import { Center, Image, Loader } from '@tf/ui';

const useImageDownload = (storageKey: string | null) => {
	return useQuery({
		queryKey: ['imageDownload', storageKey],
		queryFn: async () => {
			if (!storageKey) return;
			const res = await BackendClient.getInstance().download(storageKey);
			const arrayBuffer = await res.arrayBuffer();
			const blob = new Blob([arrayBuffer]);
			return URL.createObjectURL(blob);
		},
		enabled: Boolean(storageKey),
	});
};

interface Props {
	storageKey: string | null;
}

export const ImageDisplay: React.FC<Props> = ({ storageKey }) => {
	const { data: imageUrl, isLoading, isError } = useImageDownload(storageKey);

	if (isError) {
		throw new Error('Failed to download image');
	}

	return (
		<Center mah="64rem" m="1rem">
			{isLoading ? <Loader size={30} /> : <Image src={imageUrl} alt="image" mah="64rem" />}
		</Center>
	);
};
