import React from 'react';

import { Box, createStyles, Grid, TFText } from '@tf/ui';

const useStyles = createStyles(() => ({
	row: {
		margin: '.25rem 0',
		border: '1px solid #e8e8e8',
	},
	text: {
		cursor: 'default',
		fontSize: 13,
		display: 'flex',
	},
}));

interface Props {
	keyInfo: any;
}

export const KeyInfoTable: React.FC<Props> = ({ keyInfo }) => {
	const { classes } = useStyles();
	if (!keyInfo) {
		return null;
	}

	const data = [
		{
			name: 'Full name',
			value: keyInfo.name,
		},
		{
			name: 'Entity type',
			value: keyInfo.entityType,
		},
		{
			name: 'AKA',
			value: keyInfo.aka,
		},
		{
			name: 'Countries',
			value: keyInfo.countries,
		},
		{
			name: 'Date of birth',
			value: keyInfo.dateOfBirth,
		},
		{
			name: 'Associates',
			value: keyInfo.associates,
		},
	];

	return (
		<Box>
			{data.map((item) => (
				<Grid key={item.name} className={classes.row}>
					<Grid.Col span={2}>
						<TFText fw={600} className={classes.text}>
							{item.name}
						</TFText>
					</Grid.Col>
					<Grid.Col span={10}>
						<TFText className={classes.text}>{item.value}</TFText>
					</Grid.Col>
				</Grid>
			))}
		</Box>
	);
};
