import { createContext } from 'react';

import type { BackendTypes } from '@tf/api';

export interface FormSegmentMeta {
	identity: BackendTypes.SegmentIdentity;
	accessMode?: BackendTypes.AccessModeKind;
}

export const SegmentContext = createContext<FormSegmentMeta | null>(null);
