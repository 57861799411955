import React, { useEffect } from 'react';

import { observer, useObservable } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';
import { Button, createStyles, Flex, TextInput } from '@tf/ui';

import { useConfigContext } from '../../../../../hooks';

import { JobResultComment } from './index';

interface Props {
	initialComments?: BackendTypes.AdhocComment[];
	setComments: (comment: BackendTypes.AdhocComment[]) => void;
}

interface ComponentState {
	newComment: string;
	comments: BackendTypes.AdhocComment[] | null;
}

export const JobResultComments: React.FC<Props> = observer(({ initialComments, setComments }) => {
	const { username } = useConfigContext((s) => s.apiConfig);

	const state = useObservable<ComponentState>({
		comments: null,
		newComment: '',
	});

	useEffect(() => {
		if (initialComments) {
			state.comments.set(initialComments);
		}
	}, [initialComments]);

	const saveComment = () => {
		if (!state.newComment.get()) return;

		const newComment: BackendTypes.AdhocComment = {
			date: new Date().toString(),
			comment: state.newComment.get(),
			user: username,
		};

		state.comments.set([...(state.comments.get() || []), newComment]);
		state.newComment.set('');
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		setComments(state.comments.get());
	};

	return (
		<>
			{state.comments.get()
				? state.comments
						.get()
						?.map((item, index) => (
							<JobResultComment
								key={index}
								username={item.user as string}
								date={item.date}
								comment={item.comment}
							/>
						))
				: null}
			<Flex gap="sm">
				<TextInput
					flex={1}
					ref={(el) => el && el.focus()}
					data-autofocus
					onKeyDown={async (e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							saveComment();
						}
					}}
					value={state.newComment.get()}
					onChange={(e) => state.newComment.set(e.target.value)}
					placeholder="New comment"
				/>
				<Button onClick={saveComment}>Save</Button>
			</Flex>
		</>
	);
});
