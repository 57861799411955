import React from 'react';

import ReactMarkdown from 'react-markdown';

interface TFMarkdownTextProps {
	text: string;
	className?: string;
}

export const TFMarkdownText: React.FC<TFMarkdownTextProps> = ({ text, className }) => {
	return (
		<ReactMarkdown linkTarget="_blank" className={className}>
			{text}
		</ReactMarkdown>
	);
};
