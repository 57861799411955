import { useMutation, useQueries, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

import { jobCreateMutation } from '../mutations';
import { jobGetQuery } from '../queries';

import { useSegmentContext } from './use-segment';

const isJobRunning = (job: BackendTypes.Job) => {
	return job.status !== 'FAILED' && job.status !== 'SUCCESS';
};

export const useSegmentJobs = <JobType extends BackendTypes.Job>(jobKind: JobType['jobKind']) => {
	const { identity } = useSegmentContext();

	const allJobsQuery = useQuery({
		queryKey: ['segmentJobs', identity],
		queryFn: async (): Promise<JobType[]> => {
			const res = await BackendClient.getInstance().segmentsJobs([identity]);
			return res.json();
		},
	});

	const runningJobs = allJobsQuery.data?.filter(isJobRunning) ?? [];

	const runningJobsQueries = useQueries({
		queries: runningJobs.map((jobRecord) => {
			return {
				queryKey: ['segmentJobs', identity, jobRecord.id],
				queryFn: () => jobGetQuery<JobType>({ jobId: jobRecord.id }),
				// TODO: type
				refetchInterval: (query: any) => {
					const job = query.state.data;
					if (!job || isJobRunning(job)) {
						// schedule next request
						return 2500;
					}

					// job is finished, stop polling
					return false;
				},
			};
		}),
	});

	const createJobMutation = useMutation({
		mutationFn: async (jobPayloadData: JobType['payload']): Promise<JobType> => {
			return jobCreateMutation<JobType>({ jobKind, jobPayloadData, identity });
		},
		onSuccess: () => {
			allJobsQuery.refetch();
		},
	});

	return {
		isLoading: allJobsQuery.isFetching || runningJobsQueries.some((q) => q.isFetching),
		isError: allJobsQuery.isError,
		data: allJobsQuery.data?.map((job) => {
			if (!isJobRunning(job)) {
				return job;
			}

			// Take fresh data from polling queries or fallback to data from initial query
			return runningJobsQueries.find((jq) => jq.data?.id === job.id)?.data ?? job;
		}),
		isCreating: createJobMutation.isPending,
		createJob: createJobMutation.mutate,
	};
};
