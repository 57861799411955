import React from 'react';

import { ActionIcon, TextInput, Tooltip } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

interface Props {
	searchValue: string;
	onSearchChange: (value: string) => void;
	autoFocus?: boolean;
}

export const SearchInput: React.FC<Props> = ({ searchValue, onSearchChange, autoFocus }) => {
	return (
		<TextInput
			value={searchValue}
			onChange={(e) => onSearchChange(e.currentTarget.value)}
			leftSection={<IconSearch size={18} />}
			variant="filled"
			placeholder="Smart search..."
			autoComplete="off"
			autoFocus={autoFocus}
			size="md"
			styles={{ root: { flex: 1 }, input: { minHeight: 36, height: 36 } }}
			rightSection={
				searchValue && (
					<Tooltip label="Clear search" position="left" withArrow>
						<ActionIcon variant="transparent" color="gray" size="lg" onClick={() => onSearchChange('')}>
							<IconX size={16} />
						</ActionIcon>
					</Tooltip>
				)
			}
		/>
	);
};
