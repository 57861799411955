import React from 'react';

import { createStyles, TFMarkdownText } from '@tf/ui';

const useStyles = createStyles(() => ({
	infoContainer: {
		'& > p': {
			margin: '0.25rem 0 !important',
		},
	},
}));

interface InfoFieldProps {
	label: string;
}

export const InfoField: React.FC<InfoFieldProps> = ({ label }) => {
	const { classes } = useStyles();
	return <TFMarkdownText className={classes.infoContainer} text={label} />;
};
