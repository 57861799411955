import type { StructureNode } from './types';

/**
 * Returns the intersection point between two nodes
 * More info: https://stackoverflow.com/a/565282/104380
 * @param intersectionNode
 * @param targetNode
 */
export const getNodeIntersection = (
	intersectionNode: StructureNode,
	targetNode: StructureNode
): StructureNode['position'] => {
	const {
		width: intersectionNodeWidth,
		height: intersectionNodeHeight,
		positionAbsolute: intersectionNodePosition,
	} = intersectionNode;

	if (!intersectionNodeWidth || !intersectionNodeHeight || !intersectionNodePosition) {
		throw new Error('Invalid intersection node');
	}

	const targetPosition = targetNode.positionAbsolute!;

	const w = intersectionNodeWidth / 2;
	const h = intersectionNodeHeight / 2;

	const x2 = intersectionNodePosition.x + w;
	const y2 = intersectionNodePosition.y + h;
	const x1 = targetPosition.x + w;
	const y1 = targetPosition.y + h;

	const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
	const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
	const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
	const xx3 = a * xx1;
	const yy3 = a * yy1;
	const x = w * (xx3 + yy3) + x2;
	const y = h * (-xx3 + yy3) + y2;

	return { x, y };
};
