import React from 'react';

import type { CorporateBasicAccountData, IndividualBasicAccountData } from '@tf/utils';

import { CorporateSearchForm, IndividualSearchForm } from './index';

interface Props {
	isIndividual: boolean;
	isReadOnly: boolean;
	initialValue?: { entity?: CorporateBasicAccountData; person?: IndividualBasicAccountData };
	onSubmit: (data: { entity?: CorporateBasicAccountData; person?: IndividualBasicAccountData }) => void;
	onClose: () => void;
}

export const SearchForm: React.FC<Props> = ({
	isIndividual,
	isReadOnly,
	initialValue,
	onSubmit,
	onClose,
}) => {
	const props = {
		isReadOnly,
		submit: (data: CorporateBasicAccountData | IndividualBasicAccountData) => {
			onSubmit({ [isIndividual ? 'person' : 'entity']: data });
		},
		close: onClose,
	};

	return (
		<>
			{isIndividual ? (
				<IndividualSearchForm initialValue={initialValue?.person} {...props} />
			) : (
				<CorporateSearchForm initialValue={initialValue?.entity} {...props} />
			)}
		</>
	);
};
