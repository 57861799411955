import type { MonitoringHit, SearchHit } from './types';

export const getResultHit = (hit: MonitoringHit | SearchHit) => {
	if ('doc' in hit) {
		return {
			id: hit.doc.id,
			keyInfo: {
				name: hit.doc.name,
				entityType: hit.doc.entity_type,
				aka: hit.doc.aka?.length ? hit.doc.aka.map(({ name }) => name).join(', ') : 'N/A',
				countries: hit.doc.fields?.length
					? hit.doc.fields
							.filter((field) => field.tag === 'country_names' && field.name === 'Countries')
							.map((field) => field.value)
							.join(', ')
					: 'N/A',
				dateOfBirth: hit.doc.fields?.length
					? hit.doc.fields
							.filter((field) => field.tag === 'date_of_birth' && field.source === 'complyadvantage')
							.map((field) => field.value)
							.join(', ')
					: 'N/A',
				associates: hit.doc.associates?.length
					? hit.doc.associates.map(({ name, association }) => `${name} (${association})`).join(', ')
					: 'N/A',
			},
			sanctionList: hit.doc.sources?.length
				? hit.doc.sources.map((sourceIndex) => {
						const source = hit.doc.source_notes?.[sourceIndex];
						return {
							name: source?.name,
							url: source?.url,
							listing_started_utc: source?.listing_started_utc,
							listing_ended_utc: source?.listing_ended_utc,
							list: hit.doc.fields?.length
								? hit.doc?.fields
										.filter((field) => field.source === sourceIndex)
										.map((field) => ({
											name: field.name,
											value: field.value,
											isURL: field.tag === 'related_url',
										}))
								: [],
						};
				  })
				: [],
			adverseMedia: hit.doc.media?.length
				? hit.doc.media.map((media) => {
						return {
							title: media?.title,
							url: media?.url,
							date: media?.date,
							snippet: media?.snippet,
						};
				  })
				: [],
		};
	}

	//  https://docs.complyadvantage.com/#entities
	const sanctions = Object.values(hit.full_listing?.sanction || {});
	const peps = Object.values(hit.full_listing?.pep || {});
	const warnings = Object.values(hit.full_listing?.warning || {});
	const combinedSanctions = [...sanctions, ...peps, ...warnings];

	return {
		id: hit.id,
		keyInfo: {
			name: hit.key_information?.name,
			entityType: hit.key_information?.entity_type,
			aka: hit.key_information?.aka?.map(({ name }) => name).join(', ') || 'N/A',
			countries: hit.key_information?.country_names,
			dateOfBirth: hit.key_information?.date_of_birth,
		},
		sanctionList: combinedSanctions.map((source) => {
			return {
				name: source?.name,
				url: source?.url,
				listing_started_utc: source?.listing_started_utc,
				listing_ended_utc: source?.listing_ended_utc,
				list: source.data
					? source.data
							.filter((data) => data.source === source.source)
							.map((data) => ({
								name: data.name,
								value: data.value,
								isURL: data.tag === 'related_url',
							}))
					: [],
			};
		}),
		adverseMedia: hit.uncategorized?.media
			? Object.values(hit.uncategorized?.media).map((media) => ({
					title: media?.title,
					url: media?.url,
					date: media?.date,
					snippet: media?.snippet,
			  }))
			: [],
	};
};
