import React from 'react';

import { Show } from '@datagrid/state';

import { Button, Flex } from '@tf/ui';

interface Props {
	close: () => void;
	submit: () => void;
	isReadOnly: boolean;
}

export const SearchButtons: React.FC<Props> = ({ close, submit, isReadOnly }) => {
	return (
		<Flex gap="sm" pt="0.75rem">
			<Button fullWidth variant="light" onClick={close}>
				Close
			</Button>
			<Show<boolean> if={!isReadOnly}>
				<Button fullWidth onClick={submit}>
					Set
				</Button>
			</Show>
		</Flex>
	);
};
