import React from 'react';

import { Anchor } from '@tf/ui';

import { TFErrorTemplate } from './TFErrorTemplate';

export const TFNotFoundRoute: React.FC = () => {
	return (
		<TFErrorTemplate title="Page not found" text="Sorry, the page you were looking for was not found">
			<Anchor mt={20} variant="light" href={'/'}>
				Back to home page
			</Anchor>
		</TFErrorTemplate>
	);
};
