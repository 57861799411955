import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { BackendTypes, CSTypes } from '@tf/api';
import type { FieldDefinitionDeep, FieldRules, StructDefinitionsDeep } from '@tf/utils';
import { A } from '@tf/utils';

import type { FileDownloadFn, FileUploadFn } from '../types';

interface State {
	structDefinitions: CSTypes.GetFrontendIrResponse['structs'];
	StructDefinitionsDeep: StructDefinitionsDeep;
	enums: Record<string, any>;
	modifiers: { type: string; value: string };
	apiConfig: {
		username: string;
		fileUploadFn: FileUploadFn;
		fileDownloadFn: FileDownloadFn;
	};
	processingScripts: Record<BackendTypes.SegmentKind, string[]>;
	currency?: string;
	fieldFilters?: string[];
	compositeFields: Record<
		string,
		Record<
			string,
			{
				type: 'CONCAT';
				fields: string[];
			}
		>
	>;
	additionalData: BackendTypes.AdhocSearchEntityParams | BackendTypes.AdhocSearchPersonParams;
	accountType?: string;
}

interface Actions {
	getSegmentRules: ({ segmentKind, data }: { segmentKind: string; data: any }) => FieldRules;
	getSegmentValidation: ({
		segmentKind,
		data,
	}: {
		segmentKind: string;
		data: any;
	}) => FieldRules['validation'];
	getEnumOptions: (enumName: string) => BackendTypes.SelectOption[];
	getStructDefinition: (structureName: string) => BackendTypes.StructDefinition;
	getStructDefinitionDeep: (structureName: string) => BackendTypes.StructDefinition;
	getDataWithCompositeValues: (data: Record<string, any>) => Record<string, any>;
}

export type ConfigStore = State & Actions;

type CreateConfigStoreOptions = Omit<State, 'StructDefinitionsDeep' | 'compositeFields'>;

export const createConfigStore = ({
	structDefinitions,
	enums,
	apiConfig,
	modifiers,
	processingScripts,
	additionalData,
	currency,
	accountType,
	fieldFilters,
}: CreateConfigStoreOptions) => {
	return createStore<ConfigStore>()(
		devtools(
			(set, get) => ({
				structDefinitions,
				StructDefinitionsDeep: {},
				enums,
				apiConfig,
				modifiers,
				processingScripts,
				additionalData,
				currency,
				fieldFilters,
				accountType,
				compositeFields: {},

				getStructDefinition(structureName) {
					const structDef = get().structDefinitions[structureName || ''];
					if (!structDef) {
						throw new Error(`Structure definition not found for ${structureName}`);
					}

					return structDef;
				},

				getStructDefinitionDeep: function (structureName) {
					if (get().StructDefinitionsDeep[structureName]) {
						return get().StructDefinitionsDeep[structureName];
					}

					const structDef = get().getStructDefinition(structureName);
					const deepStrutDef = structuredClone(structDef);

					Object.values<FieldDefinitionDeep>(deepStrutDef.fields).map((value: FieldDefinitionDeep) => {
						if (value.kind === 'struct') {
							const field = get().getStructDefinitionDeep(value.ref as string);
							value.fields = field.fields;
							value.fieldKind = field.kind;
						}
					});

					if (!get().StructDefinitionsDeep[structureName]) {
						set((state) => ({
							StructDefinitionsDeep: {
								...state.StructDefinitionsDeep,
								[structureName]: deepStrutDef,
							},
						}));
					}

					return deepStrutDef;
				},

				getEnumOptions(enumName) {
					return get().enums[enumName] || [];
				},
				getSegmentRules: ({ segmentKind, data }) => {
					const rules: FieldRules = { visual: [], validation: [], calculatedFields: [] };

					const segmentScripts = processingScripts[segmentKind];
					if (!segmentScripts) {
						return rules;
					}

					segmentScripts.forEach((script) => {
						const result = eval(script)(data);
						rules.visual = A.removeDuplicates(rules.visual, result.visual);
						rules.validation = A.removeDuplicates(rules.validation, result.validation);
						rules.calculatedFields = A.removeDuplicates(
							rules.calculatedFields,
							result.calculatedFields ?? []
						);
					});

					return rules;
				},
				getSegmentValidation: ({ segmentKind, data }) => {
					return get().getSegmentRules({ segmentKind, data }).validation;
				},

				getDataWithCompositeValues(data) {
					const compositeFields = get().compositeFields;
					const segment = data;

					Object.keys(segment).forEach((segmentKind) => {
						if (compositeFields[segmentKind]) {
							Object.keys(compositeFields[segmentKind]).forEach((compositeFieldName) => {
								const compositeField = compositeFields[segmentKind][compositeFieldName];
								if (compositeField.type === 'CONCAT') {
									const compositeFieldValue = compositeField.fields
										.map((fieldName) => segment[segmentKind][fieldName])
										.join(' ')
										.trim();

									segment[segmentKind][compositeFieldName] = compositeFieldValue;
								}
							});
						}
					});

					return data;
				},
			}),
			{ name: 'TF Config Store', autoPause: true, enabled: true }
		)
	);
};
