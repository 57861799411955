import dagre from 'dagre';
import { type Node, Position } from 'reactflow';

import { NODE_HEIGHT, NODE_WIDTH } from './constants.js';
import type { StructureEdge } from './types.js';

const dagreGraph = new dagre.graphlib.Graph({});
dagreGraph.setDefaultEdgeLabel(() => ({}));

/**
 * Transforms the nodes and edges into a dagre layout
 * @param nodes
 * @param edges
 * @param direction
 */
export const getDagreLayout = (nodes: Node[], edges: StructureEdge[], direction = 'TB') => {
	const isHorizontal = direction === 'LR';
	dagreGraph.setGraph({
		rankdir: direction,
		edgesep: isHorizontal ? NODE_HEIGHT + 40 : NODE_WIDTH + 40, // Number of pixels that separate edges horizontally in the layout.
		ranksep: isHorizontal ? 180 : 120, // Number of pixels between each rank in the layout.
		nodesep: isHorizontal ? 50 : 10, // Number of pixels that separate nodes horizontally in the layout.
	});

	nodes.forEach((node) => {
		dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
	});

	edges.forEach((edge) => {
		dagreGraph.setEdge(edge.source, edge.target);
	});

	dagre.layout(dagreGraph);

	nodes.forEach((node) => {
		const nodeWithPosition = dagreGraph.node(node.id);
		node.targetPosition = isHorizontal ? Position.Left : Position.Top;
		node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

		node.position = {
			x: nodeWithPosition.x - NODE_WIDTH / 2,
			y: nodeWithPosition.y - NODE_HEIGHT / 2,
		};

		return node;
	});

	return { nodes, edges };
};
