// TODO: refactor as a traversal
const INDIVIDUAL_DETAILS_SEGMENT_NAME = 'INDIVIDUAL_ENTITY__PERSONAL_DETAILS';
const INDIVIDUAL_SELF_SEGMENT_NAME = 'INDIVIDUAL_ENTITY__SELF';

export function getSegmentsData(formData: any) {
	if (!formData[INDIVIDUAL_DETAILS_SEGMENT_NAME] || !formData[INDIVIDUAL_SELF_SEGMENT_NAME]) {
		return {};
	}

	const detailsData = formData[INDIVIDUAL_DETAILS_SEGMENT_NAME];
	const selfData = formData[INDIVIDUAL_SELF_SEGMENT_NAME];

	return {
		firstName: selfData?.first_name,
		lastName: selfData?.last_name,
		dateOfBirth: detailsData?.birth_date,
	};
}
