import type { BackendTypes } from '@tf/api';
import { T } from '@tf/utils';

export type ExtendedReviewContainer = BackendTypes.EntityReviewContainer & {
	graphLinkId: number;
	graphNodeId: number;
};

// ProcessorFn processes single connection in graph
type ProcessorFn<Result> = (params: {
	review: ExtendedReviewContainer;
	parentEntity: BackendTypes.Entity;
	connection: BackendTypes.EntityConnection;
	connectionData: BackendTypes.EntityConnectionData;
	childEntity: BackendTypes.Entity;
}) => Result;

const findEntityInReview = (review: ExtendedReviewContainer, entityId: BackendTypes.GraphId) => {
	const entity = review.listEntities.find((e) => e.graphNodeId === entityId);
	T.assertNotNullish(entity, `Entity ${entityId} found in review ${review.graphLinkId}`);
	return entity;
};

const processEntitySubgraph = <Result>({
	review,
	processorFn,
	visitedEntityIds,
	parentEntity,
}: {
	review: ExtendedReviewContainer;
	processorFn: ProcessorFn<Result>;
	visitedEntityIds: BackendTypes.GraphId[];
	parentEntity: BackendTypes.Entity;
}) => {
	const newVisitedEntityIds = [...visitedEntityIds, parentEntity.graphNodeId];
	const results: Result[] = [];

	const orderedConnections = [...parentEntity.listConnections].sort((a, b) => {
		return a.connectionKind.localeCompare(b.connectionKind);
	});

	for (const connection of orderedConnections) {
		const orderedEntities = [...connection.listFromEntities].sort((a, b) => {
			return a.graphLinkId - b.graphLinkId;
		});

		for (const connectionData of orderedEntities) {
			const childEntity = findEntityInReview(review, connectionData.graphNodeId);

			results.push(processorFn({ review, parentEntity, connection, connectionData, childEntity }));

			// Graph can have cycles, so we skip already visited entities
			if (!newVisitedEntityIds.includes(childEntity.graphNodeId)) {
				const childResults = processEntitySubgraph({
					review,
					processorFn,
					visitedEntityIds: newVisitedEntityIds,
					parentEntity: childEntity,
				});
				results.push(...childResults);
			}
		}
	}

	return results;
};

export const processReviewGraph = <Result>({
	accountId,
	review,
	processorFn,
}: {
	accountId: BackendTypes.GraphId;
	review: ExtendedReviewContainer;
	processorFn: ProcessorFn<Result>;
}) => {
	const accountEntity = findEntityInReview(review, accountId);

	return processEntitySubgraph({
		review,
		parentEntity: accountEntity,
		visitedEntityIds: [],
		processorFn,
	});
};
