import type { BackendTypes } from '@tf/api';

export const isFormDecisionRequired = (
	formSegments: BackendTypes.SegmentIdentity[],
	review: BackendTypes.EntityReviewContainer
) => {
	return formSegments.some((formSegment) => {
		return review.listSegmentReviewContainers.some((reviewSegment) => {
			return reviewSegment.identity.segmentKind === formSegment.segmentKind;
		});
	});
};
