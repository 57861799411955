import React from 'react';

import { Link, RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import type { FC } from 'react';
import { Markdown } from 'tiptap-markdown';

import { createStyles } from '../../';

Markdown.configure({
	html: false,
	tightLists: true,
	tightListClass: 'tight',
	bulletListMarker: '-',
	linkify: false,
	breaks: true,
	transformPastedText: false,
	transformCopiedText: false,
});

const useStyles = createStyles(({ colors }) => {
	const borderColor = colors.gray[3];

	return {
		toolbar: {
			borderBottom: `1px solid ${borderColor}`,
		},
		editor: {
			border: `1px solid ${colors.gray[3]}`,
			borderRadius: '4px',
			'& .ProseMirror': {
				minHeight: '300px',
			},
		},
	};
});

interface Props {
	onChange: (value: string) => void;
	content: string;
}

export const TFMarkdownTextEditor: FC<Props> = ({ onChange, content }) => {
	const { classes } = useStyles();
	const editor = useEditor({
		extensions: [StarterKit, Markdown, Link],
		content,
		onUpdate: ({ editor }) => {
			const markdown = editor.storage.markdown.getMarkdown();
			onChange(markdown);
		},
	});

	return (
		<RichTextEditor className={classes.editor} editor={editor}>
			<RichTextEditor.Toolbar className={classes.toolbar} sticky={false} stickyOffset={60}>
				<RichTextEditor.ControlsGroup>
					<RichTextEditor.H1 />
					<RichTextEditor.H2 />
					<RichTextEditor.H3 />
					<RichTextEditor.H4 />
				</RichTextEditor.ControlsGroup>

				<RichTextEditor.ControlsGroup>
					<RichTextEditor.Bold />
					<RichTextEditor.Italic />
					<RichTextEditor.ClearFormatting />
				</RichTextEditor.ControlsGroup>

				<RichTextEditor.ControlsGroup>
					<RichTextEditor.BulletList />
					<RichTextEditor.OrderedList />
				</RichTextEditor.ControlsGroup>

				<RichTextEditor.ControlsGroup>
					<RichTextEditor.Link />
				</RichTextEditor.ControlsGroup>
			</RichTextEditor.Toolbar>

			<RichTextEditor.Content />
		</RichTextEditor>
	);
};
