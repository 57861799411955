import React from 'react';

import { useFormState } from 'react-hook-form';

import { Box, Button, createStyles, Icon } from '@tf/ui';

interface Props {
	isLoading?: boolean;
	submitButtonRenderer?: (isLoading: boolean) => React.ReactNode;
}

const useStyles = createStyles(({ colors }) => ({
	toolbar: {
		display: 'flex',
		justifyContent: 'end',
		margin: '1rem 0 .5rem',
	},
	submitButton: {
		width: '100%',
		maxWidth: 200,
		height: '2.5rem',
		fontSize: '.95rem',
		'&:disabled': {
			opacity: 0.5,
			backgroundColor: colors.brand[5],
			color: '#fff',
		},
	},
}));

const FormFooter: React.FC<Props> = ({ isLoading, submitButtonRenderer }) => {
	const { classes } = useStyles();
	const { isDirty } = useFormState();
	const isReadyToSend = isDirty;

	const getSubmitButtonMeta = () => {
		if (isLoading) return 'Saving...';
		else if (isDirty) return 'Save FormLoader';
		else return 'No Changes';
	};

	if (submitButtonRenderer) {
		return <>{submitButtonRenderer(!!isLoading)}</>;
	}

	const label = getSubmitButtonMeta();

	return (
		<Box className={classes.toolbar}>
			<Button
				size="md"
				type="submit"
				disabled={!isReadyToSend}
				leftSection={<Icon.IconRefresh size={18} stroke={2.2} />}
				loading={isLoading}
				className={classes.submitButton}
			>
				{label}
			</Button>
		</Box>
	);
};

export default FormFooter;
