import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { FC } from 'react';
import type { ReactFlowInstance, ReactFlowJsonObject } from 'reactflow';
import ReactFlow, {
	Background,
	Controls,
	MiniMap,
	Panel,
	ReactFlowProvider,
	useEdgesState,
	useNodesState,
	useOnViewportChange,
	useReactFlow,
} from 'reactflow';

import { ActionIcon, createStyles, Flex, Icon, Select, Tooltip } from '@tf/ui';

import 'reactflow/dist/style.css';

import { EntityNode } from './EntityNode';
import { FloatingConnectionLine } from './FloatingConnectionLine';
import { FloatingEdge } from './FloatingEdge';
import type {
	DagreLayoutDirection,
	StructureEdge,
	StructureEdgeData,
	StructureNode,
	StructureNodeData,
	StructureNodeType,
} from './utils';
import { getDagreLayout } from './utils';

const nodeTypes: Record<StructureNodeType, React.FC<any>> = {
	account: EntityNode,
	person: EntityNode,
	company: EntityNode,
};

const edgeTypes = {
	floating: FloatingEdge,
};

const useStyles = createStyles(({ colors }) => ({
	controls: {
		backgroundColor: colors.gray[0],
	},
	minimap: {
		backgroundColor: colors.gray[0],
	},
}));

interface AccountStructure {
	nodes: StructureNode[];
	edges: StructureEdge[];
}

interface Props {
	accountStructure: AccountStructure;
	withLayoutSelect?: boolean;
	withMinimap?: boolean;
	withLocalStorage?: boolean;
	onNodeClick?: (event: React.MouseEvent, node: StructureNode) => void;
	accountId?: number;
	userId?: string;
}

export const AccountStructure: FC<Props> = (props) => {
	return (
		<ReactFlowProvider>
			<Flow {...props} />
		</ReactFlowProvider>
	);
};

export const Flow: React.FC<Props> = ({
	accountStructure,
	withLayoutSelect,
	withMinimap,
	withLocalStorage = false,
	onNodeClick,
	accountId,
	userId,
}) => {
	const { classes } = useStyles();
	const [layout, setLayout] = useState<DagreLayoutDirection>('TB');

	const [nodes, setNodes, onNodesChange] = useNodesState<StructureNodeData>([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState<StructureEdgeData>([]);

	const rfInstance = useRef<ReactFlowInstance>();

	const { setViewport } = useReactFlow();
	const storageKey = `${userId}_${accountId}_${layout}`;
	const persistentLayout = JSON.parse(localStorage.getItem(storageKey) || '{}') as ReactFlowJsonObject;

	const saveLayout = useCallback(() => {
		if (!withLocalStorage) return;
		if (rfInstance.current) {
			localStorage.setItem(storageKey, JSON.stringify(rfInstance.current.toObject()));
		}
	}, [rfInstance, storageKey]);

	const restoreInitialLayout = () => {
		const { nodes: initialNodes, edges: initialEdges } = accountStructure;
		const dagreLayout = getDagreLayout(initialNodes, initialEdges, layout);
		setNodes(dagreLayout.nodes);
		setEdges(dagreLayout.edges);
	};

	const mergeLayoutsAndRestore = () => {
		if (!withLocalStorage) return;

		if (!Object.values(persistentLayout).length) {
			restoreInitialLayout();
			return;
		}

		const { x = 0, y = 0, zoom = 1 } = persistentLayout.viewport;
		setViewport({ x, y, zoom });

		const { nodes, edges } = getDagreLayout(accountStructure.nodes, accountStructure.edges, layout);

		const persistentNodesById = new Map(persistentLayout.nodes.map((node) => [node.id, node]));

		nodes.forEach((node) => {
			const persistedNodePosition = persistentNodesById.get(node.id)?.position;

			if (persistedNodePosition) {
				node.position.x = persistedNodePosition.x;
				node.position.y = persistedNodePosition.y;
			}
		});

		setNodes(nodes);
		setEdges(edges);
	};

	useOnViewportChange({
		onEnd: saveLayout,
	});

	useEffect(() => {
		mergeLayoutsAndRestore();
	}, [layout]);

	useEffect(() => {
		if (withLocalStorage) return;
		const { nodes: initialNodes, edges: initialEdges } = accountStructure;
		const layout = getDagreLayout(initialNodes, initialEdges, 'TB');
		setNodes(layout.nodes);
		setEdges(layout.edges);
	}, [accountStructure]);

	const changeLayout = useCallback(
		(dir: DagreLayoutDirection | null) => {
			if (!dir || dir === layout) return;
			setLayout(dir);
			const newLayout = getDagreLayout(nodes, edges, dir);
			setNodes([...newLayout.nodes]);
			setEdges([...newLayout.edges]);
			if (withLocalStorage) {
				saveLayout();
			}
		},
		[nodes, edges, layout]
	);

	return (
		<ReactFlow
			fitView
			nodes={nodes}
			nodeTypes={nodeTypes}
			onNodeDragStop={saveLayout}
			onNodesChange={(changes) => {
				onNodesChange(changes);

				// Node added
				if (changes.length === 1 && changes[0].type === 'dimensions') {
					rfInstance.current?.fitView({ padding: 0.2, maxZoom: 1.2 });
				}
			}}
			// @ts-expect-error
			onNodeClick={onNodeClick}
			fitViewOptions={{ padding: 0.2, maxZoom: 1.2 }}
			minZoom={0.1}
			edges={edges}
			edgeTypes={edgeTypes}
			onEdgesChange={onEdgesChange}
			connectionLineComponent={FloatingConnectionLine}
			defaultEdgeOptions={{ animated: true }}
			proOptions={{ hideAttribution: true }}
			onInit={(reactFlowInstance) => {
				rfInstance.current = reactFlowInstance;
			}}
		>
			<Background gap={12} size={1} />
			<Controls showInteractive={false} className={classes.controls} />
			{withMinimap ? <MiniMap className={classes.minimap} /> : null}
			{withLayoutSelect ? (
				<Panel position="top-left">
					<Flex>
						<Select
							value={layout}
							data={[
								{ value: 'TB', label: 'Vertical layout' },
								{ value: 'LR', label: 'Horizontal layout' },
							]}
							onChange={(value) => changeLayout(value as DagreLayoutDirection)}
						/>
						<Tooltip label="Restore default layout" withArrow>
							<ActionIcon
								color="brand"
								variant="gradient"
								gradient={{ from: 'brand', to: 'blue' }}
								size="lg"
								ml={9}
								h={36}
								w={36}
								onClick={() => {
									restoreInitialLayout();
									localStorage.removeItem(storageKey);
								}}
							>
								<Icon.IconRefresh size={20} stroke={1.8} />
							</ActionIcon>
						</Tooltip>
					</Flex>
				</Panel>
			) : null}
		</ReactFlow>
	);
};
