import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Input, NumberInput } from '@tf/ui';

import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

export default function PercentageInput({
	name,
	label,
	description,
	placeholder,
	isReadOnly,
	isRequired,
	tooltip,
}: DefaultInputProps) {
	const form = useFormContext();

	useEffect(() => {
		const value = form.getValues(name);
		form.setValue(name, value || '');
	}, []);

	return (
		<Controller
			control={form.control}
			name={name}
			render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
				<Input.Wrapper label={label} labelProps={{ required: isRequired }}>
					<NumberInput
						id={name}
						ref={ref}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						max={100}
						min={0}
						maxLength={3}
						description={description}
						error={Boolean(error)}
						rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
						placeholder={placeholder}
						readOnly={isReadOnly}
						hideControls
					/>
				</Input.Wrapper>
			)}
		/>
	);
}
