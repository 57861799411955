import React from 'react';

import { Button, Center, Icon, Stack, TFNotifier, TFText, useClipboard } from '../../main';

interface Props {
	onContinue: () => void;
}

export const MobileNotice: React.FC<Props> = ({ onContinue }) => {
	const clipboard = useClipboard();
	return (
		<Stack p="16px" h="100dvh" gap="12px">
			<Stack w="100%" mt="20vh">
				<Center
					m="0 auto"
					w="68px"
					h="68px"
					mb="12px"
					p="12px"
					sx={{ backgroundColor: '#F9FAFB', borderRadius: '12px' }}
				>
					<Icon.IconDeviceImac size="33" />
				</Center>

				<TFText sx={{ textAlign: 'center' }} c="#111827" size="24px" lh="32px" fw="600">
					Optimized for desktop
				</TFText>
				<TFText sx={{ textAlign: 'center' }} size="14px" lh="20px" c="#6B7280">
					For the best experience, we recommend using our software on a desktop device
				</TFText>
			</Stack>

			<Stack justify="flex-end" flex="1" w="100%">
				<Button h="40px" onClick={onContinue} variant="light" fullWidth>
					Continue on mobile
				</Button>
				<Button
					h="40px"
					onClick={() => {
						clipboard.copy(window.location.href);
						TFNotifier.success('Url copied');
					}}
					fullWidth
				>
					Copy link to continue on desktop
				</Button>
			</Stack>
		</Stack>
	);
};
