import React, { useEffect } from 'react';

import { observer, Show, useObservable } from '@datagrid/state';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';

import type { BackendTypes } from '@tf/api';
import type { SearchHit } from '@tf/shared';
import {
	Box,
	Button,
	createStyles,
	Group,
	Icon,
	Input,
	Loader,
	Modal,
	ScrollArea,
	Stack,
	TFNotifier,
	TFSection,
	TFText,
} from '@tf/ui';
import type {
	CorporateBasicAccountData,
	IndividualBasicAccountData,
	InstantCheckParams,
} from '@tf/utils';
import { S } from '@tf/utils';

import { useConfigContext, useSegmentContext } from '../../../../hooks';
import { jobCreateMutation } from '../../../../mutations';
import { jobGetQuery } from '../../../../queries';
import { idenfyErrorMessages } from '../../../../utils';
import { JobStatus } from '../../types';

import { EditDataButton } from './EditDataButton';
import { JobResultCard } from './JobResultCard';
import { JobResultCommentsView } from './JobResultComments';
import { JobResultTableContainer } from './JobResultTable';

const useStyles = createStyles(({ colors, radius }) => ({
	infoBlockWrapper: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '.75rem .75rem',
		borderRadius: radius.sm,
		backgroundColor: colors.gray[1],
	},
	infoBlockContent: {
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		cursor: 'default',
		color: colors.dark[7],
	},
	infoBlockLoading: {
		flex: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginRight: '.5rem',
		cursor: 'default',
		color: colors.brand[6],
	},
}));

// TODO: get rid of unnecessary additionalData argument
const isIndividual = (
	additionalData: BackendTypes.AdhocSearchEntityParams | BackendTypes.AdhocSearchPersonParams
): additionalData is BackendTypes.AdhocSearchPersonParams => {
	if (additionalData) {
		return 'firstName' in additionalData;
	}

	return false;
};

interface ComponentState {
	isSearching: boolean;
	hits: SearchHit[] | null;
	isModalOpen: boolean;
	isTableModalOpen: boolean;
	searchParams: {
		selected_match_ids?: string[] | null;
		comments?: BackendTypes.MonitoringComment[];
		payload?: {
			entity?: CorporateBasicAccountData;
			person?: IndividualBasicAccountData;
		};
	} | null;
	comments: BackendTypes.MonitoringComment[] | null;
	jobId: string | null;
	jobStatus: JobStatus | null;
}

interface Props {
	name: string;
	isReadOnly: boolean;
}

type JobResult = Promise<{
	result: { rawResponse: { hits: SearchHit[] }; searchId: string };
	status: string;
}>;

export const BackgroundCheck: React.FC<Props> = observer(({ name, isReadOnly }) => {
	const { classes } = useStyles();

	const formContext = useFormContext();
	const { identity } = useSegmentContext();
	const { additionalData } = useConfigContext((s) => s);

	if (!additionalData && !isReadOnly) {
		return null;
	}

	const state = useObservable<ComponentState>({
		isSearching: false,
		isModalOpen: false,
		isTableModalOpen: false,
		searchParams: null,
		hits: null,
		jobId: null,
		jobStatus: null,
		comments: null,
	});

	const createJobMutation = useMutation({
		mutationFn: ({
			jobPayloadData,
			identity,
		}: {
			identity: BackendTypes.SegmentIdentity;
			jobPayloadData: BackendTypes.JobPayload;
		}) =>
			jobCreateMutation({
				jobKind: 'COMPLY_ADVANTAGE_SEARCH',
				jobPayloadData,
				identity,
			}),
	});

	const getJob = useQuery({
		queryKey: ['complyAdvantageJobGet', state.jobId.get()],
		queryFn: () => jobGetQuery({ jobId: state.jobId.get() as string }) as JobResult,
		enabled:
			!!state.jobId.get() &&
			state.jobStatus.get() !== JobStatus.FAILED &&
			state.jobStatus.get() !== JobStatus.SUCCESS,
		refetchInterval: 2500,
	});

	useEffect(() => {
		if (getJob.data) {
			if (getJob.data.result?.rawResponse?.hits) {
				state.hits.set(getJob.data?.result?.rawResponse?.hits);

				// Search was started and now finishing successfully
				if (state.isSearching.get()) {
					state.isTableModalOpen.set(true);
				}
			}

			const jobStatus = getJob.data.status;
			state.jobStatus.set(jobStatus as JobStatus);
			if (jobStatus === JobStatus.FAILED) {
				state.isSearching.set(false);
				TFNotifier.error('Could not perform search. Please try again later.');
			}
			if (jobStatus === JobStatus.SUCCESS) {
				state.isSearching.set(false);
			}
		}

		if (getJob.error) {
			state.isSearching.set(false);
			TFNotifier.error('Something went wrong. Please try again later');
		}
	}, [getJob.data, getJob.error]);

	useEffect(() => {
		const instantCheckParams: InstantCheckParams = {};
		const initialData = formContext.getValues(name);

		if (initialData) {
			state.searchParams.set(initialData);

			if (initialData?.id) {
				state.jobId.set(initialData?.id);
			}
		} else {
			if (!isIndividual(additionalData)) {
				instantCheckParams.entity = {
					entity_name: additionalData?.entityName,
				};
			} else {
				instantCheckParams.person = {
					first_name: additionalData?.firstName,
					last_name: additionalData?.lastName,
					middle_name: additionalData?.middleName,
					// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
					birth_date: additionalData?.dateOfBirth,
				};
			}

			state.searchParams.set({ payload: instantCheckParams });
		}
	}, []);

	useEffect(() => {
		formContext.setValue(`${name}`, state.searchParams.get());
	}, [state.searchParams.get()]);

	const onSearch = async () => {
		try {
			state.searchParams.assign({ selected_match_ids: null });
			state.isSearching.set(true);

			const resultJob = await createJobMutation.mutateAsync({
				// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
				jobPayloadData: getSearchParams(state.searchParams.get()?.payload),
				identity,
			});

			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			state.jobStatus.set(resultJob.status);
			state.jobId.set(resultJob.id);

			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			const hits = (resultJob.rawResponse?.hits as SearchHit[]) || [];
			state.hits.set(hits);
		} catch (e) {
			const defaultMessage = 'Could not perform search. Please try again later.';
			const error = e as any;
			if (error?.status === 424) {
				const res = await error?.json();
				const errorMessage = idenfyErrorMessages[res?.code] ?? defaultMessage;
				TFNotifier.error(errorMessage);
			} else {
				TFNotifier.error(defaultMessage);
			}
		}
	};

	const checkAsMatch = async ({
		ids,
		comments,
	}: {
		ids: (string | number)[] | null;
		comments: BackendTypes.AdhocComment[] | null;
	}) => {
		state.isTableModalOpen.toggle();

		if (!ids || !ids.length) {
			return;
		}

		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		state.searchParams.assign({ selected_match_ids: ids });
		formContext.setValue(`${name}.selected_match_ids`, ids);

		if (comments && comments.length) {
			formContext.setValue(`${name}.comments`, comments);
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			state.searchParams.assign({ comments });
		}

		formContext.setValue(`${name}.id`, state.jobId.get());
	};

	const getSearchParams = (
		payload: InstantCheckParams
	): {
		person?: BackendTypes.AdhocSearchPersonParams;
		entity?: BackendTypes.AdhocSearchEntityParams;
	} => {
		const queryData: Record<string, any> = {};

		for (const entityType in payload) {
			queryData[entityType] = {};

			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			for (const key in payload[entityType]) {
				const camelCaseKey = S.fromSnakeToCamel(key);
				// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
				queryData[entityType][camelCaseKey] = payload[entityType][key];
			}
		}

		return queryData;
	};

	const searchName =
		state.searchParams.get()?.payload?.entity?.entity_name ||
		`${state.searchParams.get()?.payload?.person?.first_name} ${
			state.searchParams.get()?.payload?.person?.last_name
		}`;

	return (
		<TFSection
			title={<Input.Label m={0}>Background check</Input.Label>}
			icon={<Icon.IconListSearch size={18} />}
			actions={
				<Box hidden={isReadOnly}>
					<EditDataButton
						isReadOnly={isReadOnly}
						onSubmit={(data) => state.searchParams.set({ payload: data })}
						initialValue={state.searchParams.get()?.payload}
						isIndividual={isIndividual(additionalData)}
					/>
				</Box>
			}
		>
			<Stack gap="md">
				<Show if={isReadOnly && !state.searchParams.get()?.selected_match_ids}>
					<Box className={classes.infoBlockWrapper}>
						<Group className={classes.infoBlockContent}>
							<TFText size="sm" lineClamp={1}>
								Search was not performed for this account
							</TFText>
						</Group>
					</Box>
				</Show>

				<Show if={!isReadOnly}>
					<Button onClick={onSearch} variant="light" disabled={state.isSearching.get()}>
						<TFText inherit>
							{state.isSearching.get() ? (
								<Group>
									<Loader size={18} color="gray" />
									Searching...
								</Group>
							) : state.searchParams.get()?.selected_match_ids ? (
								'New search'
							) : (
								'Search'
							)}
						</TFText>
					</Button>
				</Show>

				<Show<boolean> if={!!state.searchParams.get()?.selected_match_ids?.length}>
					{state.searchParams.get()?.selected_match_ids &&
					// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
					state.searchParams.get()?.selected_match_ids[0] < 0 ? (
						// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
						<JobResultCommentsView comments={state.searchParams.get()?.comments} />
					) : (
						state.searchParams
							.get()
							?.selected_match_ids?.map((matchId: string) => (
								<JobResultCard
									key={matchId}
									hit={state.hits.get()?.find((hit) => hit.doc.id === matchId)}
								/>
							))
					)}
				</Show>
			</Stack>

			<Modal
				scrollAreaComponent={ScrollArea.Autosize}
				size={900}
				withinPortal
				opened={state.isTableModalOpen.get()}
				title={`Background check`}
				onClose={state.isTableModalOpen.toggle}
			>
				<JobResultTableContainer
					searchName={searchName}
					searchId={getJob.data?.result?.searchId}
					hits={state.hits.get()}
					checkAsMatch={checkAsMatch}
				/>
			</Modal>
		</TFSection>
	);
});
