import React from 'react';

import { Controller } from 'react-hook-form';

import { Icon, Input, MonthPickerInput } from '@tf/ui';
import { D, fmt } from '@tf/utils';

import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

export default function DateMonthYearInput({
	name,
	label,
	description,
	placeholder,
	className,
	isRequired,
	isReadOnly,
	tooltip,
}: DefaultInputProps) {
	return (
		<Controller
			name={name}
			render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
				<Input.Wrapper
					id={name}
					className={className}
					label={label}
					labelProps={{ required: isRequired }}
				>
					<MonthPickerInput
						id={name}
						value={typeof value === 'string' ? D.jsDateFromString(value) : undefined}
						ref={ref}
						description={description}
						error={Boolean(error)}
						variant="filled"
						valueFormat="MMM YYYY"
						locale="en"
						readOnly={isReadOnly}
						placeholder={placeholder || isReadOnly ? 'Date unspecified' : 'Select Date...'}
						onChange={(v) => {
							const nextValue = v ? fmt.toDate(v, { preset: 'date_struct' }) : undefined;
							onChange(nextValue);
						}}
						onBlur={onBlur}
						rightSection={<TooltipIcon tooltip={tooltip} error={error} />}
						leftSection={!isReadOnly && <Icon.IconCalendar size={16} />}
					/>
				</Input.Wrapper>
			)}
		/>
	);
}
