import { nanoid } from 'nanoid';

import type { BackendTypes } from '@tf/api';
import { S, T } from '@tf/utils';

import type { ExtendedFormDefinition } from './forms/forms';
import { extractForms, getFormHash } from './forms/forms';
import { getFormStatuses } from './forms/getFormStatuses';
import { isFormDecisionRequired } from './forms/isFormDecisionRequired';
import type { ExtendedReviewContainer } from './processReviewGraph';
import { processReviewGraph } from './processReviewGraph';

type FormTask = {
	id: string;
	type: 'PENDING_FILL' | 'VALIDATION_ERRORS' | 'PENDING_REVIEW' | 'REJECTED';
	label: string;
	// TODO: pass generic later?
	form: ExtendedFormDefinition<BackendTypes.FormDefinition>;
};

const isFormTask = (task: Task): task is FormTask => {
	return (
		task.type === 'PENDING_FILL' ||
		task.type === 'VALIDATION_ERRORS' ||
		task.type === 'PENDING_REVIEW' ||
		task.type === 'REJECTED'
	);
};

type ConnectionTask = {
	id: string;
	type: 'REQUIRED_CONNECTION';
	label: string;
	connection: {
		connectionKind: BackendTypes.EntityConnectionKind;
		parentEntityId: BackendTypes.GraphId;
		parentEntityKind: BackendTypes.EntityKind;
	};
};

type ShareholdersSumTask = {
	id: string;
	type: 'CORRECT_SHAREHOLDERS_PERCENTAGE_SUM';
	label: string;
};

type PartnershipsSumTask = {
	id: string;
	type: 'CORRECT_PARTNERSHIP_PERCENTAGE_SUM';
	label: string;
};

type FinalizeReviewTask = {
	id: string;
	type: 'FINALIZE_REVIEW';
	label: string;
};

export type Task =
	| FormTask
	| ConnectionTask
	| ShareholdersSumTask
	| FinalizeReviewTask
	| PartnershipsSumTask;

const getFormTask = ({
	review,
	form,
}: {
	review: ExtendedReviewContainer;
	form: ExtendedFormDefinition<BackendTypes.FormDefinition>;
}): FormTask | null => {
	const id = nanoid();

	const formStatus = getFormStatuses({
		containers: review.listSegmentReviewContainers,
		segmentIdentities: form.segmentIdentities,
	});

	if (review.state === 'DRAFT') {
		if (formStatus.structure === 'PENDING_FILL') {
			return {
				id,
				form,
				type: 'PENDING_FILL',
				label: `Fill in form "${form.label}"`,
			};
		}

		if (formStatus.structure === 'VALIDATION_ERRORS') {
			return {
				id,
				form,
				type: 'VALIDATION_ERRORS',
				label: `Correct errors in form "${form.label}"`,
			};
		}
	} else {
		if (!formStatus.review && isFormDecisionRequired(form.segmentIdentities, review)) {
			return {
				id,
				form,
				type: 'PENDING_REVIEW',
				label: `Form "${form.label}" pending compliance check`,
			};
		}

		if (formStatus.review === 'REJECTED') {
			return {
				id,
				form,
				type: 'REJECTED',
				label: `Form "${form.label}" rejected by compliance`,
			};
		}
	}

	return null;
};

const getShareholderProblemsTasks = ({
	review,
	forms,
	entityId,
}: {
	review: ExtendedReviewContainer;
	forms: ExtendedFormDefinition<BackendTypes.FormDefinition>[];
	entityId: BackendTypes.GraphId;
}) => {
	const tasks: Task[] = [];

	const possibleProblems: {
		segmentKind: string;
		problemType: 'invalidShareholdersPercentage' | 'invalidPartnershipsPercentage';
		label: string;
	}[] = [
		{
			segmentKind: 'CORPORATE_ENTITY__SHAREHOLDER_INFORMATION',
			problemType: 'invalidShareholdersPercentage',
			label: 'Specify a correct shareholding percentage',
		},
		{
			segmentKind: 'PARTNERSHIP_ENTITY__PARTNER_INFORMATION',
			problemType: 'invalidPartnershipsPercentage',
			label: 'Specify a correct partnership percentage',
		},
	];

	possibleProblems.forEach(({ segmentKind, problemType, label }) => {
		if (review.problems[problemType].length > 0) {
			const problematicForm = forms.find((f) =>
				f.segmentIdentities.some(
					(s) => review.problems[problemType].includes(s.graphId) && s.segmentKind === segmentKind
				)
			);

			if (problematicForm) {
				const formStatus = getFormStatuses({
					containers: review.listSegmentReviewContainers,
					segmentIdentities: problematicForm.segmentIdentities,
				});

				if (formStatus.structure === 'OK') {
					tasks.push({
						id: nanoid(),
						type: 'PENDING_FILL',
						label,
						form: problematicForm,
					});
				}
			}
		}
	});

	if (review.problems.invalidShareholdersPercentageSum.includes(entityId)) {
		tasks.push({
			id: nanoid(),
			type: 'CORRECT_SHAREHOLDERS_PERCENTAGE_SUM',
			label: 'Correct the percentage of the shareholders',
		});
	}

	if (review.problems.invalidPartnershipsPercentageSum.includes(entityId)) {
		tasks.push({
			id: nanoid(),
			type: 'CORRECT_PARTNERSHIP_PERCENTAGE_SUM',
			label: 'Correct the percentage of the partnership',
		});
	}

	return tasks;
};

const getConnectionTasks = ({
	entity,
	entityInfo,
}: {
	entity: BackendTypes.Entity;
	entityInfo: BackendTypes.EntityInformation;
}): ConnectionTask[] => {
	const tasks: ConnectionTask[] = [];

	for (const connection of entity.listConnections) {
		if (connection.isLogicalRequired && connection.listFromEntities.length === 0) {
			const connectionInfo = entityInfo.possibleConnections.find(
				(c) => c.kind === connection.connectionKind
			);

			tasks.push({
				id: nanoid(),
				type: 'REQUIRED_CONNECTION',
				label: `Add ${connectionInfo?.labels?.singular ?? S.prettify(connection.connectionKind)}`,
				connection: {
					connectionKind: connection.connectionKind,
					parentEntityId: entity.graphNodeId,
					parentEntityKind: entity.entityKind,
				},
			});
		}
	}

	return tasks;
};

type EntityTasks = {
	entityId: BackendTypes.GraphId;
	entityName: string;
	tasks: Task[];
};

// Returns tasks from a minimal graph subtree (just two nodes and edge)
// All tasks belong to a single entity (child entity in subtree)
const getEntityTasksForSubtree = ({
	formDefs,
	review,
	parentEntity,
	connectionKind,
	connectionData,
	entity,
	entityInfo,
}: {
	formDefs: BackendTypes.FormDefinition[];
	review: ExtendedReviewContainer;
	parentEntity: BackendTypes.Entity;
	connectionKind: BackendTypes.EntityConnectionKind;
	connectionData: BackendTypes.EntityConnectionData;
	entity: BackendTypes.Entity;
	entityInfo: BackendTypes.EntityInformation;
}): EntityTasks => {
	const graphIdsForSubtree = [parentEntity.graphNodeId, connectionData.graphLinkId, entity.graphNodeId];
	const segmentIdentities = [
		...(connectionKind === 'APPLICANT' ? parentEntity.listSegments : []).map((s) => s.SegmentIdentity),
		...connectionData.listSegments.map((s) => s.SegmentIdentity),
		...entity.listSegments.map((s) => s.SegmentIdentity),
		...review.listSegmentReviewContainers
			.filter((s) => s.identity.graphId === connectionData.graphNodeId)
			.map((s) => s.identity),
	].filter((s) => graphIdsForSubtree.includes(s.graphId));

	const forms = extractForms({
		formDefs,
		segmentIdentities,
	});

	const tasks: Task[] = forms
		.map((form) => {
			return getFormTask({ review, form });
		})
		.filter(T.isNotNullish);

	const shareholderProblemsTasks = getShareholderProblemsTasks({
		forms,
		review,
		entityId: entity.graphNodeId,
	});
	tasks.push(...shareholderProblemsTasks);

	const connectionTasks = getConnectionTasks({ entity, entityInfo });
	tasks.push(...connectionTasks);

	return {
		entityId: entity.graphNodeId,
		entityName: entity.name,
		tasks,
	};
};

const getFinalizeReviewTask = ({
	review,
	reviewTasks,
}: {
	review: ExtendedReviewContainer;
	reviewTasks: ReviewTasks;
}): FinalizeReviewTask | null => {
	if (review.connectionKind !== 'APPLICANT') {
		return null;
	}

	const hasPendingReviewTasks = reviewTasks.entities.some((entityTasks) => {
		return entityTasks.tasks.some((t) => t.type === 'PENDING_REVIEW');
	});
	const isUnderReview = review.state === 'UNDER_REVIEW' || review.state === 'UPDATE_REQUIRED';
	const isRiskLevelSegmentsFilled = review.listSegmentReviewContainers.every((s) => {
		return (
			(s.identity.segmentKind === 'COMMON__COMPLIANCE_FEEDBACK' ||
				s.identity.segmentKind === 'COMMON__COMPLIANCE_DATES') &&
			s.status === 'OK'
		);
	});

	if (!hasPendingReviewTasks && isUnderReview && !isRiskLevelSegmentsFilled) {
		return {
			id: nanoid(),
			type: 'FINALIZE_REVIEW',
			label: 'Please finalise the review to activate the account',
		};
	}

	return null;
};

// NOTE: only compare tasks inside single entity
const isSameTask = (taskA: Task, taskB: Task) => {
	if (isFormTask(taskA) && isFormTask(taskB)) {
		return taskA.form.name === taskB.form.name && getFormHash(taskA.form) === getFormHash(taskB.form);
	}

	if (taskA.type === 'REQUIRED_CONNECTION' && taskB.type === 'REQUIRED_CONNECTION') {
		return (
			taskA.connection.parentEntityId === taskB.connection.parentEntityId &&
			taskA.connection.connectionKind === taskB.connection.connectionKind
		);
	}

	// eslint-disable-next-line sonarjs/prefer-single-boolean-return
	if (
		taskA.type === 'CORRECT_SHAREHOLDERS_PERCENTAGE_SUM' &&
		taskB.type === 'CORRECT_SHAREHOLDERS_PERCENTAGE_SUM'
	) {
		return true;
	}

	return false;
};

type ReviewTasks = {
	reviewLinkId: BackendTypes.GraphId;
	reviewConnectionKind: BackendTypes.EntityConnectionKind;
	entities: EntityTasks[];
};

export const getAccountTasks = ({
	formDefs,
	entityDefs,
	accountId,
	reviews,
}: {
	formDefs: BackendTypes.FormDefinition[];
	entityDefs: Record<BackendTypes.EntityKind, BackendTypes.EntityInformation>;
	accountId: BackendTypes.GraphId;
	reviews: ExtendedReviewContainer[];
}) => {
	const result: ReviewTasks[] = [];

	reviews.forEach((review) => {
		const reviewTasks: ReviewTasks = {
			reviewLinkId: review.graphLinkId,
			reviewConnectionKind: review.connectionKind,
			entities: [],
		};

		processReviewGraph({
			accountId,
			review,
			processorFn: ({ parentEntity, connection, connectionData, childEntity }) => {
				const entityTasks = getEntityTasksForSubtree({
					formDefs,
					review,
					parentEntity,
					connectionKind: connection.connectionKind,
					connectionData,
					entity: childEntity,
					entityInfo: entityDefs[childEntity.entityKind],
				});

				// Add tasks for connections from same review group
				// Technically this connection required for an account, but we add it to applicant entity tasks to avoid duplication in UI
				if (parentEntity.graphNodeId === accountId && childEntity.graphNodeId === review.graphNodeId) {
					const accountConnectionTasks = getConnectionTasks({
						entity: parentEntity,
						entityInfo: entityDefs[parentEntity.entityKind],
					});
					entityTasks.tasks.push(...accountConnectionTasks);
				}

				// Deduplicate tasks: in case of using existing entity in multiple connections
				// 	some tasks can be already presented for this entity in another review
				const sameEntityInOtherReview = result
					.filter((r) => r.reviewLinkId !== review.graphLinkId)
					.flatMap((r) => r.entities)
					.find((e) => e.entityId === entityTasks.entityId);
				if (sameEntityInOtherReview) {
					entityTasks.tasks = entityTasks.tasks.filter((task) => {
						return !sameEntityInOtherReview.tasks.some((t) => isSameTask(task, t));
					});
				}

				// Merge tasks: also, entity can be used in different connections in current review
				const sameEntityInReview = reviewTasks.entities.find((e) => e.entityId === entityTasks.entityId);
				if (sameEntityInReview) {
					entityTasks.tasks.forEach((task) => {
						const isExists = sameEntityInReview.tasks.some((t) => isSameTask(task, t));
						if (!isExists) {
							sameEntityInReview.tasks.push(task);
						}
					});
				} else if (entityTasks.tasks.length) {
					reviewTasks.entities.push(entityTasks);
				}
			},
		});

		const finalizeReviewTask = getFinalizeReviewTask({ review, reviewTasks });
		if (finalizeReviewTask) {
			const mainEntity = review.listEntities.find((e) => e.graphNodeId === review.graphNodeId);
			const entityTasks = reviewTasks.entities.find((e) => e.entityId === mainEntity?.graphNodeId);

			if (entityTasks) {
				entityTasks.tasks.push(finalizeReviewTask);
			} else if (mainEntity) {
				reviewTasks.entities.push({
					entityId: mainEntity.graphNodeId,
					entityName: mainEntity.name,
					tasks: [finalizeReviewTask],
				});
			}
		}

		if (reviewTasks.entities.length) {
			result.push(reviewTasks);
		}
	});

	return result;
};
