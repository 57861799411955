import React, { useState } from 'react';

import { Box, Checkbox, Flex, Radio } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import type { FC, ReactNode } from 'react';

const useStyles = createStyles(({ colors }) => ({
	card: {
		border: `1px solid ${colors.gray[4]}`,
		padding: '12px 8px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			border: '1px solid #60A5FA',
		},
		'&.selected': {
			border: `1px solid ${colors.brand[5]}`,
			boxShadow: `0px 0px 0px 1px ${colors.brand[5]} inset`,
		},
	},
	inputHovered: {
		borderColor: colors.brand[3],
		background: '#EFF6FF',
	},
	inputSelected: {
		borderColor: colors.brand[5],
	},
}));

interface Props {
	type: 'checkbox' | 'radio';
	children?: ReactNode;
	isActive: boolean;
	onClick: () => void;
}

export const TFListCard: FC<Props> = ({ type, children, isActive, onClick }) => {
	const { classes } = useStyles();
	const [state, setState] = useState<'default' | 'hovered'>('default');

	const handleMouseEnter = () => {
		if (!isActive) {
			setState('hovered');
		}
	};

	const handleMouseLeave = () => {
		if (!isActive) {
			setState('default');
		}
	};

	const getClassByState = () => {
		if (state === 'hovered') {
			return classes.inputHovered;
		}
		if (isActive) {
			return classes.inputSelected;
		}
	};

	return (
		<Box
			className={`${classes.card} ${isActive ? 'selected' : ''}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={onClick}
		>
			<Flex align="center">
				{type === 'checkbox' && (
					<Checkbox
						classNames={{ input: getClassByState() }}
						size="xs"
						readOnly
						checked={Boolean(isActive)}
					/>
				)}
				{type === 'radio' && (
					<Radio
						classNames={{ radio: getClassByState() }}
						size="xs"
						readOnly
						checked={Boolean(isActive)}
					/>
				)}
				{children}
			</Flex>
		</Box>
	);
};
