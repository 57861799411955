import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, createStyles, Flex, Group, Stack } from '@tf/ui';

import { useSegmentContext } from '../../../hooks';
import GenericField from '../GenericField';
import { TooltipIcon } from '../TooltipIcon';

const FIELD_NAMES = {
	STREET_NAME: 'street_name',
	CITY: 'city',
	REGION: 'region',
	POSTCODE: 'postcode',
	COUNTRY: 'country',
	HOUSE_NUMBER: 'house_number',
};

const useStyles = createStyles(() => ({
	title: {
		fontWeight: 500,
		fontSize: '14px',
		margin: '.45rem 0',
	},
}));

interface AddressInputProps {
	structDef: BackendTypes.StructDefinition;
	name: string;
	title?: string;
	tooltip?: string;
}

export function AddressInput({ structDef, title, name, tooltip }: AddressInputProps) {
	const fields = structDef.fields;
	const { classes } = useStyles();
	const { identity } = useSegmentContext();

	const getFieldDefinition = (fieldName: string) => fields.find((f) => f.name === fieldName);

	const generateFieldName = (fieldDef: BackendTypes.FieldDefinition) => {
		return `${name.replace(identity.segmentKind + '.', '')}.${fieldDef.name}`;
	};

	const renderGenericField = (fieldDef?: BackendTypes.FieldDefinition) =>
		fieldDef && (
			<GenericField
				def={{
					...fieldDef,
					name: generateFieldName(fieldDef),
				}}
			/>
		);

	return (
		<Box>
			{title && (
				<Flex align={'center'}>
					<div className={classes.title}>{title}</div>
					<TooltipIcon style={{ marginLeft: '4px' }} tooltip={tooltip} />
				</Flex>
			)}
			<Stack gap={12}>
				{renderGenericField(getFieldDefinition(FIELD_NAMES.COUNTRY))}
				<Group grow>
					{renderGenericField(getFieldDefinition(FIELD_NAMES.REGION))}
					{renderGenericField(getFieldDefinition(FIELD_NAMES.CITY))}
				</Group>
				{renderGenericField(getFieldDefinition(FIELD_NAMES.STREET_NAME))}
				<Group grow>
					{renderGenericField(getFieldDefinition(FIELD_NAMES.HOUSE_NUMBER))}
					{renderGenericField(getFieldDefinition(FIELD_NAMES.POSTCODE))}
				</Group>
			</Stack>
		</Box>
	);
}
