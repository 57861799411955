import { MarkerType } from 'reactflow';

import type { BackendTypes } from '@tf/api';

import { getOwnershipInfo } from '../../getOwnershipInfo';
import { type ExtendedReviewContainer, processReviewGraph } from '../../processReviewGraph';

import type { StructureEdge, StructureNode } from './types';

export interface AccountWithReviews {
	meta: BackendTypes.Entity;
	reviews: ExtendedReviewContainer[];
}

type EntitiesDefs = Record<
	BackendTypes.EntityKind,
	{
		label: string;
		possibleConnections: { kind: BackendTypes.EntityConnectionKind; labels?: { singular: string } }[];
	}
>;

// Transforms review graph into account structure
export const getAccountStructure = (
	account: AccountWithReviews,
	entitiesDefs: EntitiesDefs,
	selectedEntityId?: number
) => {
	const accountId = account.meta.graphNodeId;
	const position = { x: 0, y: 0 } as const;

	const nodes: Record<string, StructureNode> = {
		[`node.${accountId}`]: {
			id: `node.${accountId}`,
			type: 'account',
			position,
			data: {
				label: account.meta.name,
				kind: account.meta.entityKind,
				kindLabel: entitiesDefs[account.meta.entityKind].label || account.meta.entityKind,
				graphId: account.meta.graphNodeId,
				tasks: [],
				meta: {
					accountId,
					connectionKind: 'ACCOUNT',
					connectionId: account.meta.graphNodeId,
				},
			},
		},
	};
	const edges: Record<string, StructureEdge> = {};

	for (const review of account.reviews) {
		processReviewGraph({
			accountId,
			review,
			processorFn: ({ parentEntity, connection, connectionData, childEntity, review }) => {
				const isUBO = connection.connectionKind === 'UBO';

				const targetNodeId = `node.${childEntity.graphNodeId}`;

				nodes[targetNodeId] = {
					id: targetNodeId,
					type: childEntity.entityKind === 'INDIVIDUAL_ENTITY' ? 'person' : 'company',
					position,
					data: {
						label: childEntity.name,
						badge: isUBO ? 'UBO' : undefined,
						kind: childEntity.entityKind,
						kindLabel: entitiesDefs[childEntity.entityKind].label || childEntity.entityKind,
						graphId: childEntity.graphNodeId,
						isSelected: selectedEntityId === childEntity.graphNodeId,
						tasks: [],
						meta: {
							accountId,
							connectionKind: review.connectionKind,
							connectionId: review.graphLinkId,
							data: review.data,
						},
					},
				};

				if (isUBO) {
					// Don't add edge for UBO connection in graph visualization
					return;
				}

				const sourceNodeId = `node.${parentEntity.graphNodeId}`;
				const edgeId = `edge.${connectionData.graphLinkId}`;
				const ownershipInfo = getOwnershipInfo(
					connection.connectionKind,
					review.data,
					connectionData.graphLinkId
				);
				const connectionLabel =
					entitiesDefs[parentEntity.entityKind].possibleConnections.find(
						(c) => c.kind === connection.connectionKind
					)?.labels?.singular || connection.connectionKind;
				const label = `${connectionLabel}${
					ownershipInfo ? ` (${ownershipInfo.OwnershipPercentage}%)` : ''
				}`;

				edges[edgeId] = {
					id: edgeId,
					source: sourceNodeId,
					target: targetNodeId,
					type: 'floating',
					markerEnd: {
						type: MarkerType.Arrow,
						height: 24,
						width: 24,
					},
					data: {
						label,
						kind: connection.connectionKind,
						graphId: connectionData.graphLinkId,
						isSelected: selectedEntityId === connectionData.graphLinkId,
						meta: {
							accountId,
							connectionKind: review.connectionKind,
							connectionId: review.graphLinkId,
						},
					},
				};
			},
		});
	}

	return { nodes: Object.values(nodes), edges: Object.values(edges) };
};
