import React from 'react';

import { observer, useObservable } from '@datagrid/state';

import type { BackendTypes } from '@tf/api';
import type { SearchHit } from '@tf/shared';
import { Box, Button, createStyles, Flex, Group, Icon, TFText } from '@tf/ui';
import { saveFile } from '@tf/utils';

import { useDownloadMonitoringSearchCertificateMutation } from '../../../../../queries';

import { JobResultTable } from './index';

const useStyles = createStyles(({ colors }) => ({
	footer: {
		position: 'absolute',
		backgroundColor: 'white',
		width: '100%',
		padding: '0.75rem 1rem',
		bottom: '0',
		height: '4rem',
		borderTop: `1px solid ${colors.gray[2]}`,
	},
	table: {
		marginBottom: '4rem',
	},
}));

interface ComponentState {
	checkedMatches: (string | number)[] | null;
	comments: BackendTypes.AdhocComment[] | null;
}

interface Props {
	hits: SearchHit[] | null;
	searchId?: string;
	searchName: string;
	checkAsMatch: ({
		ids,
		comments,
	}: {
		ids: (string | number)[] | null;
		comments: BackendTypes.AdhocComment[] | null;
	}) => void;
}

export const JobResultTableContainer: React.FC<Props> = observer(
	({ hits, searchName, checkAsMatch, searchId }) => {
		const { classes } = useStyles();

		const state = useObservable<ComponentState>({
			checkedMatches: null,
			comments: null,
		});

		const downloadCertificateMutation = useDownloadMonitoringSearchCertificateMutation();

		if (!hits) return null;

		return (
			<>
				<Box p="1rem" className={classes.table}>
					<JobResultTable
						hits={hits}
						checkAsMatch={({ ids, comments }) => {
							state.checkedMatches.set(ids);
							state.comments.set(comments);
						}}
					/>
				</Box>
				<Group justify="space-between" className={classes.footer} gap="sm">
					<TFText fw="500">
						{hits.length || 0} possible matches
						{state.checkedMatches.get() && (state.checkedMatches.get() as (string | number)[])[0] === -1
							? ', none of them are positive matches'
							: `, ${state.checkedMatches.get()?.length} selected`}
					</TFText>
					<Flex justify="space-between" gap="8px">
						<Button
							onClick={async () => {
								if (!searchId) return;
								const res = await downloadCertificateMutation.mutateAsync(searchId);
								saveFile(res, `${searchName || 'background check'}.pdf`);
							}}
							variant="light"
							disabled={downloadCertificateMutation.isPending}
							leftSection={<Icon.IconFileDownload size={14} stroke={2.5} />}
						>
							Download search certificate
						</Button>
						<Button
							miw="125px"
							onClick={() => {
								checkAsMatch({ ids: state.checkedMatches.get(), comments: state.comments.get() });
							}}
						>
							Submit
						</Button>
					</Flex>
				</Group>
			</>
		);
	}
);
