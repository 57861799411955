import React from 'react';

import { Box } from '@mantine/core';
import { useHover } from '@mantine/hooks';

interface Props {
	size?: 'default' | number;
}

const TFLogo: React.FC<Props> = ({ size = 'default' }) => {
	const color = '#000';
	const { hovered, ref } = useHover<any>();

	if (size !== 'default') {
		return (
			<Box w={size} h={size} bg="#FFF">
				<svg width={18} height={18} viewBox="0 0 88 88" fill="#FFF">
					<g clipPath="url(#clip0_408_28411)">
						<path
							d="M88 0V88H0V0H88ZM72 16H16V72H27V34.78L52.36 60.13L60.14 52.35L34.78 27H72V16Z"
							fill="#000"
						/>
					</g>

					<defs>
						<clipPath id="clip0_408_28411">
							<rect width="88" height="88" fill="#FFF" />
						</clipPath>
					</defs>
				</svg>
			</Box>
		);
	}

	return (
		<svg
			width="150"
			height="30"
			viewBox="0 0 308 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			ref={ref}
		>
			<g clipPath="url(#clip0_300_28618)">
				<path
					d="M38.23 0.994629H0V39.0122H6.94585V12.7853L25.5469 31.283L30.4576 26.3996L11.8566 7.9019H38.23V0.994629Z"
					fill={hovered ? '#004feb' : color}
				/>
				<path
					d="M84.1699 39.1092V10.4233H90.6782V14.4778C93.6441 10.4233 97.2976 9.69109 101.687 9.89831V16.1079C95.1791 14.9475 90.6782 18.3183 90.6782 24.0582V39.1092H84.1699Z"
					fill={color}
				/>
				<path
					d="M105.869 29.9497V10.4229H112.377V28.8445C112.377 32.4778 114.176 34.2667 117.878 34.2667C122.483 34.2667 125.609 31.6903 125.609 27.9535V10.4229H132.117V39.1087H125.609V35.9521C123.81 38.4249 120.316 39.9514 116.718 39.9514C110.05 39.9514 105.869 36.111 105.869 29.9497Z"
					fill={color}
				/>
				<path
					d="M136.882 28.1609H143.286C143.606 31.4212 143.974 34.267 151.281 34.267C154.983 34.267 157.421 32.8441 157.421 31.0067C157.421 27.636 152.336 27.3252 150.065 26.9038C145.516 26.0611 137.993 25.6951 137.993 18.3733C137.993 13.3725 143.078 9.73926 150.065 9.73926C157.053 9.73926 162.186 13.4208 162.714 19.1608H156.205C155.573 16.4255 153.774 15.3203 150.065 15.3203C146.627 15.3203 144.509 16.4255 144.509 18.1592C144.509 20.632 149.746 21.157 151.975 21.6336C157.164 22.7387 163.936 23.6367 163.936 30.689C163.936 36.1596 158.748 39.9517 151.288 39.9517C141.973 39.9517 136.681 35.6899 136.889 28.1609H136.882Z"
					fill={color}
				/>
				<path
					d="M194.31 39.109V15.9489H190.233V10.4231H194.31V8.37161C194.31 2.58332 196.901 0 202.249 0C203.84 0 205.424 0.158867 206.486 0.524953V5.8919C201.777 5.47056 200.874 6.25799 200.874 9.15213V10.4162H206.486V15.942H200.874V39.109H194.31Z"
					fill={color}
				/>
				<path
					d="M209.244 24.8939C209.244 15.7901 215.328 9.73242 224.698 9.73242C234.068 9.73242 240.097 15.7832 240.097 24.8939C240.097 34.0046 233.902 39.9448 224.698 39.9448C215.495 39.9448 209.244 33.8941 209.244 24.8939ZM233.645 24.8939C233.645 18.8915 230.255 15.2099 224.698 15.2099C219.142 15.2099 215.704 18.9467 215.704 24.8939C215.704 30.841 219.142 34.3154 224.698 34.3154C230.255 34.3154 233.645 30.6338 233.645 24.8939Z"
					fill={color}
				/>
				<path
					d="M244.272 39.1092V10.4233H250.78V14.4778C253.746 10.4233 257.399 9.69109 261.789 9.89831V16.1079C255.281 14.9475 250.78 18.3183 250.78 24.0582V39.1092H244.272Z"
					fill={color}
				/>
				<path
					d="M266.394 39.109V10.4231H272.903V12.9511C275.021 10.8997 277.667 9.73926 280.682 9.73926C284.544 9.73926 287.246 11.1069 288.885 13.8422C291.372 11.1552 294.546 9.73926 298.151 9.73926C304.555 9.73926 307.993 13.2689 307.993 19.741V39.109H301.533V20.7909C301.533 16.7363 300.366 15.3203 296.984 15.3203C293.066 15.3203 290.476 17.8967 290.476 21.6888V39.109H283.967V20.7909C283.967 16.688 282.856 15.3203 279.466 15.3203C275.549 15.3203 272.903 17.952 272.903 21.6888V39.109H266.394Z"
					fill={color}
				/>
				<path
					d="M184.517 15.9349V10.416H178.939V4.22705H172.368V10.416H166.791V15.9349H172.368V30.6335V32.1048C172.368 35.6275 173.542 37.9414 175.876 39.1088C177.064 39.7028 178.543 39.9998 180.328 39.9998C182.231 39.9998 183.718 39.7926 184.725 39.4748V34.0526C180.016 34.5292 178.953 34.0526 178.953 31.6834V29.4179H178.932V15.9349H184.51H184.517Z"
					fill={color}
				/>
				<path
					d="M77.9325 15.9349V10.416H72.355V4.22705H65.7842V10.416H60.2067V15.9349H65.7842V30.6335V32.1048C65.7842 35.6275 66.958 37.9414 69.2918 39.1088C70.4796 39.7028 71.959 39.9998 73.7441 39.9998C75.6473 39.9998 77.1337 39.7926 78.1409 39.4748V34.0526C73.4316 34.5292 72.3689 34.0526 72.3689 31.6834V29.4179H72.348V15.9349H77.9255H77.9325Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_300_28618">
					<rect width="308" height="40" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default TFLogo;
