import React, { useCallback } from 'react';

import type { Edge, EdgeProps } from 'reactflow';
import { EdgeLabelRenderer, getBezierPath, useStore } from 'reactflow';

import { Box } from '@tf/ui';

import { getEdgeParams } from './utils';

/**
 * Floating edge component for connections
 */
export const FloatingEdge: React.FC<EdgeProps> = ({ id, data, source, target, markerEnd, style }) => {
	const sourceNode = useStore<any>(useCallback((store) => store.nodeInternals.get(source), [source]));
	const targetNode = useStore<any>(useCallback((store) => store.nodeInternals.get(target), [target]));

	const edgesBetweenSameNodes: Edge[] = useStore<any>((s) => {
		return s.edges.filter((e) => {
			return (
				(e.source === source && e.target === target) || (e.source === target && e.target === source)
			);
		});
	});
	const thisEdgeIndex = edgesBetweenSameNodes.findIndex((e) => e.id === id);

	if (!sourceNode || !targetNode) {
		return null;
	}

	const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX: sx,
		sourceY: sy,
		sourcePosition: sourcePos,
		targetPosition: targetPos,
		targetX: tx,
		targetY: ty,
	});

	// Stack labels if there are multiple connections between same nodes
	const labelStepPx = 30;
	const labelYInitial = labelY - (labelStepPx * (edgesBetweenSameNodes.length - 1)) / 2;

	return (
		<>
			<path id={id} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<Box
					sx={({ radius }) => ({
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${
							labelYInitial + labelStepPx * thisEdgeIndex
						}px)`,
						background: '#ffcc00',
						padding: '.25rem .75rem',
						borderRadius: radius.xl,
						fontSize: '.7rem',
						fontWeight: 600,
					})}
					className="nodrag nopan"
				>
					{data.label}
				</Box>
			</EdgeLabelRenderer>
		</>
	);
};
