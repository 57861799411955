import React, { useState } from 'react';

import { Button, Flex, Icon } from '@tf/ui';

import { VerificationResultModal } from '../../../VerificationResultModal';

import type { DocumentCheckJob } from './types';

interface Props {
	job: DocumentCheckJob;
}

export const DocumentsCheckIdenfyResult: React.FC<Props> = ({ job }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => setIsModalOpen(!isModalOpen);

	const files = [job.payload.frontSide, job.payload.backSide];

	const selectOptions = files
		.map((file) => {
			if (!file) return;
			return {
				label: file.filename,
				value: file.storageKey,
			};
		})
		.filter((option): option is { label: string; value: string } => Boolean(option));

	return (
		<>
			<Flex justify="end">
				<Button
					size="sm"
					variant="subtle"
					onClick={toggleModal}
					leftSection={<Icon.IconFileInfo size={18} stroke={1.5} />}
				>
					Details
				</Button>
			</Flex>
			<VerificationResultModal
				job={job}
				isOpen={isModalOpen}
				onModalClose={toggleModal}
				imageUrls={selectOptions}
			/>
		</>
	);
};
