import React, { useEffect, useState } from 'react';

import { Checkbox, Flex, Radio, UnstyledButton } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import type { FC } from 'react';

import { TFText } from '../../main';

const useStyles = createStyles(({ colors }) => ({
	card: {
		border: `1px solid ${colors.gray[3]}`,
		padding: '8px',
		borderRadius: '4px',
		cursor: 'pointer',
		'&:hover': {
			border: `1px solid #60A5FA`,
			backgroundColor: '#EFF6FF',
			color: '#155EEF',
		},
		'&.selected': {
			border: `1px solid #2563EB`,
			color: '#2563EB',
		},
	},
	inputHovered: {
		backgroundColor: '#EFF6FF',
		borderColor: '#60A5FA',
	},
	inputDefault: {
		borderColor: colors.gray[3],
	},
}));

interface Props {
	type: 'checkbox' | 'radio';
	label: string;
	isActive: boolean;
	onClick: () => void;
	style?: React.CSSProperties;
	id?: string;
}

export const TFChip: FC<Props> = ({ type, id, label, isActive, onClick, style }) => {
	const { classes } = useStyles();
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (isActive) {
			setIsHovered(false);
		}
	}, [isActive]);

	const handleMouseEnter = () => {
		if (!isActive) {
			setIsHovered(true);
		}
	};

	const handleMouseLeave = () => {
		if (!isActive) {
			setIsHovered(false);
		}
	};

	const inputClass = isActive ? '' : isHovered ? classes.inputHovered : classes.inputDefault;

	return (
		<UnstyledButton
			className={`${classes.card} ${isActive ? 'selected' : ''}`}
			sx={{
				'&:active': {
					transform: 'translateY(1px)',
				},
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={onClick}
			style={style}
		>
			<Flex w="fit-content" align="center">
				{type === 'checkbox' ? (
					<Checkbox
						id={id}
						classNames={{ input: inputClass }}
						size="xs"
						readOnly
						checked={Boolean(isActive)}
					/>
				) : (
					<Radio
						id={id}
						classNames={{ radio: inputClass }}
						size="xs"
						readOnly
						checked={Boolean(isActive)}
					/>
				)}
				<TFText
					styles={{ root: { width: 'fit-content !important' } }}
					w="500"
					size="14px"
					lh="normal"
					ml="8px"
				>
					{label}
				</TFText>
			</Flex>
		</UnstyledButton>
	);
};
