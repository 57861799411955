import React from 'react';

import type { MutableRefObject } from 'react';

import type { BackendTypes, CSTypes } from '@tf/api';
import type { FormSegment, FormValues, SubmitHandler } from '@tf/utils';

import { FormBuilder } from './components';
import { ConfigContext } from './contexts';
import type { FormControl } from './providers';
import { FormProvider } from './providers';
import { createConfigStore } from './stores';
import type { FileDownloadFn, FileUploadFn, FormBuilderStyles } from './types';

export type { FormControl } from './providers';
export type { FileDownloadFn, FileUploadFn } from './types';

export interface FormBuilderProps {
	defs: Pick<CSTypes.GetFrontendIrResponse, 'structs' | 'options'>;
	segments: FormSegment[];
	processingScripts?: Record<BackendTypes.SegmentKind, string[]>;
	onSubmit: SubmitHandler;
	apiConfig: {
		username: string;
		fileUploadFn: FileUploadFn;
		fileDownloadFn: FileDownloadFn;
	};
	currency?: string;
	initialValues?: FormValues;
	formControlRef?: MutableRefObject<FormControl | undefined>;
	// TODO: exchange this prop to normal visibility state
	modifiers?: { type: string; value: string };
	shouldValidate?: boolean;
	validateInitialValues?: boolean;
	isLoading?: boolean;
	styles?: Partial<FormBuilderStyles>;
	segmentPreRenderer?: (segment: FormSegment) => React.ReactNode;
	submitButtonRenderer?: (isLoading: boolean) => React.ReactNode;
	additionalData?: BackendTypes.AdhocSearchEntityParams | BackendTypes.AdhocSearchPersonParams;
	accountType?: string;
	fieldFilters?: string[];
}

function FormBuilderWrapper({
	defs,
	segments,
	processingScripts = {},
	onSubmit,
	apiConfig,
	initialValues = {},
	formControlRef,
	shouldValidate,
	validateInitialValues,
	isLoading,
	styles,
	segmentPreRenderer,
	additionalData,
	submitButtonRenderer,
	modifiers,
	currency,
	accountType,
	fieldFilters,
}: FormBuilderProps) {
	const configContextValue = createConfigStore({
		structDefinitions: defs.structs,
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		modifiers,
		enums: defs.options,
		currency,
		apiConfig,
		processingScripts,
		accountType,
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		additionalData,
		fieldFilters,
	});

	return (
		<ConfigContext.Provider value={configContextValue}>
			<FormProvider
				initialValues={initialValues}
				shouldValidate={shouldValidate}
				validateInitialValues={validateInitialValues}
				formControlRef={formControlRef}
				segments={segments}
				submit={async ({ data, segmentValidationStatuses }) => {
					await onSubmit({ values: data, segmentValidationStatuses });
				}}
			>
				<FormBuilder
					isLoading={isLoading}
					styles={styles}
					segmentPreRenderer={segmentPreRenderer}
					submitButtonRenderer={submitButtonRenderer}
				/>
			</FormProvider>
		</ConfigContext.Provider>
	);
}

export default FormBuilderWrapper;
