import { useContext } from 'react';

import { useStore } from 'zustand';

import { FormContext } from '../contexts';
import type { FormStore } from '../stores';

export function useFormStore<T>(
	selector: (state: FormStore) => T,
	equalityFn?: (left: T, right: T) => boolean
): T {
	const store = useContext(FormContext);
	if (!store) {
		throw new Error('Missing FormContext.Provider in the tree');
	}
	return useStore(store, selector, equalityFn);
}
