import type { BackendTypes } from '@tf/api';

import type { ExtendedReviewContainer } from '../processReviewGraph';

type Review = Pick<
	ExtendedReviewContainer,
	'graphNodeId' | 'listEntities' | 'listSegmentReviewContainers'
>;

export const getSegmentsForReview = (account: BackendTypes.Entity, review: Review) => {
	const rootEntity = review.listEntities.find((e) => e.graphNodeId === review.graphNodeId);

	if (!rootEntity) {
		throw new Error('No review root entity found');
	}

	return [
		...account.listSegments.map((s) => s.SegmentIdentity),
		...rootEntity.listSegments.map((s) => s.SegmentIdentity),
		...review.listSegmentReviewContainers.map((s) => s.identity),
	];
};
