import React from 'react';

import { getBezierPath } from 'reactflow';

import { getEdgeParams } from './utils';

/**
 * Floating connection line
 * TODO: Add proper types
 * @param targetX
 * @param targetY
 * @param sourcePosition
 * @param targetPosition
 * @param sourceNode
 * @constructor
 */
export const FloatingConnectionLine: React.FC<any> = ({
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	sourceNode,
}) => {
	if (!sourceNode) {
		return null;
	}

	const targetNode: any = {
		id: 'connection-target',
		width: 1,
		height: 1,
		position: { x: targetX, y: targetY },
		data: { label: 'pseudo', entityKind: 'pseudo' },
	};

	const { sx, sy } = getEdgeParams(sourceNode, targetNode);
	const [edgePath] = getBezierPath({
		sourceX: sx,
		sourceY: sy,
		sourcePosition,
		targetPosition,
		targetX,
		targetY,
	});

	return (
		<g>
			<path fill="none" stroke="#222" strokeWidth={1.5} className="animated" d={edgePath} />
			<circle cx={targetX} cy={targetY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
		</g>
	);
};
